import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select';
interface UserDetailsData {
  id:string;
  JobTitle: string;
  JobID: string;
  Department: string;
  JobExperience: string;
  JOBfamily: string;
  CreatedOn: string;
}

interface ShowCompanyData {
  error?: string;
  message?: string;
  organisation_details?: {
      data?: {
          id: string;
          type: string;
          attributes: {
              company_name: string;
          }
      }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Star
  token: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedLanguage:string;
  jobLevelValue : string;
  jobTitle:string;
  department:string;
  jobLevel:{id:number, name: string}[];
  jdstyle:{id:number, name: string}[];
  jobFamily:{id:number, name: string}[];
  jobSummary:string;
  keySkills:{id:number, name: string}[];
  keyWords:{keywords: string}[];
  viewedId:string;
  active: number;
  progressScreen:boolean;
  progress:number;
  progressOpen:boolean;
  jobdescResponse:string;
  emptyscreen:boolean;
  formscreen:boolean;
  jobDetailScreen:boolean;
  selectedOption:string;
  editJobDetailScreen:boolean;
  JobDescdashboard:boolean;
  calendarOpen: boolean,
  selectedDate: null | string, 
  tempDate: null | string,
  selectedStatus: string[];
  userStatus: string[];
  selectedDetails: string;
  userdetails: string[];
  anchorEl: null;
  openedMenu:null;
  userdetailsData:any;
  deleteJobDetail:boolean;
  dataId:string;
  opencompanyDialog: boolean;
  companyName: string;
  errorMsg:string;
  jobId:string;
  jobDescriptChatGpt: any;
  loader: boolean;
  searchText: string;
  startDate: string;
  endDate: string;
  descriptionLoader:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfchatgptintegration9Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createJobDescApiCallId:string="";
  getJobDescLevelApiCallId:string="";
  getJobDescExpApiCallId:string="";
  getJobDescKeySkillApiCallId:string="";
  getJobDescKeyWordApiCallId:string="";
  getJobDescJobFamilyApiCallId:string="";
  getJobDescdetailsApiCallId:string="";
  getJobDescdetailsDeleteApiCallId:string="";
  getCompanyApiCallId: string = "";
  getSearchJobApiCallId: string = "";
  getCalendarJobApiCallId: string ="";

  interval: number | undefined;
  intervals:number | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedLanguage:"",
      jobLevelValue:"",
      jobTitle:"",
      department:"",
      jobSummary:"",
      jobLevel:[],
      jdstyle:[],
      jobFamily:[],
      keySkills:[],
      keyWords:[],
      active: 0,
      progressScreen:false,
      progress:0,
      progressOpen:false,
      jobdescResponse:"",
      jobDescriptChatGpt: {},
      emptyscreen:false,
      formscreen:false,
      jobDetailScreen:false,
      viewedId: "",
      selectedOption:"",
      editJobDetailScreen:false,
      JobDescdashboard:true,
      calendarOpen: false,
      selectedDate: null,
      tempDate: null,
      selectedStatus: [],
      userStatus: ["Active", "Inactive"],
      selectedDetails: "string",
      userdetails: ["View details","Edit details" ,"Delete details"],
      anchorEl: null,
      openedMenu:null,
      userdetailsData: [],
      deleteJobDetail:false,
      dataId:"",
      opencompanyDialog: false,
      companyName: "",
      errorMsg:"",
      jobId:"",
      loader: false,
      searchText: "",
      startDate: "",
      endDate: "",
      descriptionLoader:false
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getJobDescLevelApiCallId) {
        this.setState({jobLevel:responseJson})
      }
      if (apiRequestCallId === this.getJobDescExpApiCallId) {
        this.setState({jdstyle:responseJson})
      }
      if (apiRequestCallId === this.getJobDescKeySkillApiCallId) {
        const keySkillData = responseJson.data.map((data:any) => ({
          id: data.attributes.id,
          name: data.attributes.skill_name,
      }));
      this.setState({ keySkills: keySkillData });
      }
      this.jobdescRevieve(apiRequestCallId ,responseJson )
      this.jobdescRecieve(apiRequestCallId ,responseJson )
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const tokenMeta = await getStorageData('signInResponse', true);
    this.setState({
      token: tokenMeta?.meta?.token
    })
    const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.getCompanyApiCall();
    this.jobDescriptionGetCall()  
    this.jobDescriptionExpGetCall()
    this.jobDescriptionKeySkillGetCall()
      this.jobDescriptionKeyWordGetCall()
    this.jobDescriptionJobFamilyGetCall()
      this.jobDescriptiondetailsGetCall()
  }
 
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(prevState.progressScreen !== this.state.progressScreen) {
    if(this.state.progressScreen){
      this.interval = setInterval(() => {
        if(this.state.active === 6) {
          this.setState({active: 0})
        }else
        this.setState({active: this.state.active + 1})
      }, 300) as unknown as number

      
      this.intervals = setInterval(() => {
        if(this.state.progress >= 80){
          clearInterval(this.interval);
          clearInterval(this.intervals);
          this.setState({progress : 100});
          this.setState({progressOpen : true});
        }
        this.setState({progress : this.state.progress + 1})
      },100) as unknown as number
    }
  }
  }

  async componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    if (this.intervals) {
      clearInterval(this.intervals);
      this.intervals = undefined;
    }
  }

  jobDescriptionCall = async(data:any) => {
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody: any = {
        "job_description": {
          "job_title": data.jobTitle,
          "department": data.DepartmentTeam,
          "job_level_id": data.jobLevel,
          "job_family_id": data.jobFamily,
          "job_experience_id": data.jdStyle,
          "job_summary": data.jobSummary,
          "key_skills": data.keySkills,
          "key_words": data.keyWords
        }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createJobDescApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createJobDescApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jobDescriptionGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescLevelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJobDescLevelApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jobDescriptionExpGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescExpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescExpApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jobDescriptionKeySkillGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescKeySkillApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescKeySkillApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  jobDescriptionKeyWordGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescKeyWordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescKeyWordApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  jobDescriptionJobFamilyGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescJobFamilyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescJobFamilyAPiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  jobDescriptiondetailsGetCall = () => {
    this.setState({loader:true})
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescdetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescDetailsAPiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  jobDescriptiondetailsDeleteGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescdetailsDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescDetailsDeleteAPiEndPoint}/${this.state.dataId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompanyApiCall = () => {
    const headers = {
        "token": this.state.token
    };
    const getMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyApiCallId = getMsg.messageId;
    getMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.showCompanyApiEndPoint}`
    );
    getMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(getMsg.id, getMsg);
}

filterAndSearchApiCall = () => {
  this.setState({loader:true})
  const headers = {
      "token": this.state.token
  };

  const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getSearchJobApiCallId = getMsg.messageId;
  getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.filterAndSearchApiEndPoint}${this.state.searchText}`
  );
  getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
  );
  runEngine.sendMessage(getMsg.id, getMsg);
}

filterAndCalendarApiCall = () => {
  this.setState({loader:true})
  const headers = {
      "token": this.state.token
  };
  const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCalendarJobApiCallId = getMsg.messageId;
  getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.filterAndCalendarApiEndPoint}start_date=${this.state.startDate}&end_date=${this.state.endDate}`
  );
  getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
  );
  runEngine.sendMessage(getMsg.id, getMsg);
}

handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
  const searchValue = event.target.value;
  this.setState({ searchText: searchValue });
};

handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
  if (event.key === 'Enter') {
    console.log("key", event.key)
      this.filterAndSearchApiCall();
  }
};

handleSearchIcon = () => {
  console.log("icon")
  this.filterAndSearchApiCall();
}

  
  closeProgressModal = () => {
    this.setState({progressOpen: false, progressScreen: false, formscreen:true ,jobDetailScreen:false, progress:0  });
  };

  openProgressModal = () => {
    this.setState({ progressOpen: false, progressScreen:false , jobDetailScreen:true, progress:0  });
  };

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
  }

  handleTrueCondition = (condition: any, truePart: any) => {
    return condition && truePart
  }

  backToForm = () => {
    this.setState({formscreen: true , progressScreen:false, progress:0, descriptionLoader:false})
    clearInterval(this.intervals);
  }

  createNewJob = () => {
    if (!this.state.companyName) {
      this.setState({ opencompanyDialog: true })
  }
  else{
    this.setState({formscreen: true , emptyscreen:false , JobDescdashboard:false})
  }
  }
  
  navigateToDashboard = () => {
    if(this.state.userdetailsData.length > 0) {
      this.setState({JobDescdashboard:true , formscreen:false})
    }else if(this.state.userdetailsData.length === 0){
      this.setState({emptyscreen:true , formscreen:false})
    }
  }

  handleChange = (event:SelectChangeEvent<string>) => {
    this.setState({ selectedOption: event.target.value });
  };

  openCalendar = () => {
    this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
  };

  handleDateChange = (newDate:any) => {
    this.setState({ tempDate: newDate });
    const [startDate, endDate] = newDate; 
    const formatDateStart = startDate .toLocaleDateString() .split('/') .map((val:string) => val.padStart(2, '0')); 
    const formattedStartDate = `${formatDateStart[2]}-${formatDateStart[0]}-${formatDateStart[1]}`;
     const formatDateEnd = endDate .toLocaleDateString() .split('/') .map((val:string) => val.padStart(2, '0')); 
     const formattedEndDate = `${formatDateEnd[2]}-${formatDateEnd[0]}-${formatDateEnd[1]}`;

     this.setState({startDate:formattedStartDate , endDate:formattedEndDate})

  };

  handleCalendarCancelBtn = () => {
    this.setState({ tempDate: null, calendarOpen: false , selectedDate:"" });
  };

  handleCalendarSaveBtn = () => {
    console.log("selectedDate",this.state.tempDate)
     this.filterAndCalendarApiCall()
    if (this.state.tempDate) {
      this.setState({
        selectedDate: this.state.tempDate,
        tempDate: null,
        calendarOpen: false,
      });
    }
  };  

  formatDateRange = (dateRange: any) => {
    console.log("dateRange",dateRange , new Date())
    if (Array.isArray(dateRange) && dateRange.length === 2) {
      const [startDate, endDate] = dateRange; 

      const formatDateStart = startDate .toLocaleDateString() .split('/') .map((val:string) => val.padStart(2, '0')); 
      const formattedStartDate = `${formatDateStart[2]}-${formatDateStart[0]}-${formatDateStart[1]}`;
       const formatDateEnd = endDate .toLocaleDateString() .split('/') .map((val:string) => val.padStart(2, '0')); 
       const formattedEndDate = `${formatDateEnd[2]}-${formatDateEnd[0]}-${formatDateEnd[1]}`;


       console.log("Datesformat",dateRange,formattedStartDate,formattedEndDate)
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    return 'Select Start Date - End Date';
  };

  handleStatus = (event: SelectChangeEvent<string> , index:any) => {
    const newSelectedStatuses = [...this.state.selectedStatus];
  newSelectedStatuses[index] = event.target.value;
  this.setState({ selectedStatus: newSelectedStatuses });
}

handleClickOpen = (index:any, id: string) => {
  this.setState((prevState) => ({ openedMenu: prevState.openedMenu === index ? null : index  , dataId: id}));
};

handleSelect = (value: string, id: string) => {
  this.setState({ selectedDetails: value, openedMenu: null });
  if (value === "View details") {
    this.setState({ jobDetailScreen : true , JobDescdashboard:false , viewedId: id});
    const selectedData = this.state.userdetailsData.find((element: any) => element.id === id);
    console.log("selectedData", selectedData);
    this.setState({
      jobDescriptChatGpt: selectedData?.chatgptResponse
    })
  } else if(value === "Edit details"){
    this.setState({ editJobDetailScreen : true , JobDescdashboard:false });
  }else{
    this.setState({ deleteJobDetail : true , JobDescdashboard:true });
  }
};

closeJobDeletePopupModal = () => {
  this.setState({deleteJobDetail:false});
};

openJobDeletePopupModal = () => {
  this.jobDescriptiondetailsDeleteGetCall();
  localStorage.removeItem("jobId")
  this.setState({deleteJobDetail:false});
};

jobdescRevieve = (apiRequestCallId:string, responseJson : any) => {
  if (apiRequestCallId === this.getJobDescKeyWordApiCallId) {
    const keyWordsData = responseJson?.keywords?.map((data:any) => ({
      keywords: data,
  }));
  this.setState({ keyWords: keyWordsData });
  }
  if (apiRequestCallId === this.createJobDescApiCallId) {
    console.log("responseJson",responseJson)

    this.setState({formscreen: false ,progress: 100, progressScreen:true, jobdescResponse:responseJson.message})
    if(responseJson?.data){
      this.setState({
        viewedId: responseJson.data?.id,
        jobDescriptChatGpt: responseJson.data?.attributes?.chatgpt_response,
      })
    }
    this.setState({loader:false})
  }
  if (apiRequestCallId === this.getJobDescJobFamilyApiCallId) {
    this.setState({jobFamily:responseJson})
  }
  if (apiRequestCallId === this.getJobDescdetailsApiCallId) {
    const jobDetailsData = responseJson?.data?.map((data:any) => ({
      id: data.attributes.id,
      JobTitle: data.attributes.job_title,
      JobID: data.attributes.id,
      Department: data.attributes.department,
      JobExperience: data.attributes.job_experience,
      JOBfamily: data.attributes.job_family,
      CreatedOn: data.attributes.created_at.split("T")[0], 
      chatgptResponse: data.attributes.chatgpt_response
  }))

  const jobId = responseJson?.data?.map((data:any) => ({
    id: data.attributes.id,
  }))

  localStorage.setItem("jobId", jobId)
  console.log("responseJson",responseJson)

  if(jobDetailsData?.length === 0){
    this.setState({
      emptyscreen: true
    })
  }
    
  this.setState({userdetailsData:jobDetailsData , loader:false})
  }
  if (apiRequestCallId === this.getJobDescdetailsDeleteApiCallId) {
    this.jobDescriptiondetailsGetCall()
  }
}
jobdescRecieve = (apiRequestCallId:string, responseJson : any) => {
  if (apiRequestCallId === this.getCompanyApiCallId) {
    this.companyApiResponse(responseJson)
}
if (apiRequestCallId === this.getSearchJobApiCallId) {
  const jobDetailsSearchData = 
  responseJson.data.map((data:any) => ({
    id: data.attributes.id,
    JobTitle: data.attributes.job_title,
    JobID: data.attributes.id,
    Department: data.attributes.department,
    JobExperience: data.attributes.job_experience,
    JOBfamily: data.attributes.job_family,
    CreatedOn: data.attributes.created_at.split("T")[0], 
    chatgptResponse: data.attributes.chatgpt_response
}))
if
(responseJson.data.length === 0)
{
  this.setState({emptyscreen:true, JobDescdashboard:false,loader:false})
}
else{
  this.setState({
    userdetailsData:jobDetailsSearchData ,loader:false})
}
}
if (apiRequestCallId === this.getCalendarJobApiCallId) {
  const jobDetailsCalendarData = 
  responseJson?.data.map((data:any) =>
     ({
    id: 
    data.attributes.id,
    JobTitle: 
    data.attributes.job_title,
    JobID: 
    data.attributes.id,
    Department: 
    data.attributes.department,
    JobExperience: 
    data.attributes.job_experience,
    JOBfamily: 
    data.attributes.job_family,
    CreatedOn: 
    data.attributes.created_at.split("T")[0], 
    chatgptResponse: 
    data.attributes.chatgpt_response
}
))
if(responseJson.data.length === 0)
  {
  this.setState({emptyscreen:true, 
    JobDescdashboard:false,loader:false})
}
else{
  this.setState({userdetailsData:jobDetailsCalendarData ,
    loader:false})
}
}
}
handleScreen = () => {
if(this.state.userdetailsData?.length > 0){
    this.setState({emptyscreen: false,jobDetailScreen:false , JobDescdashboard: true}, () => this.jobDescriptiondetailsGetCall())
  }
}
handleCloseCompanyDialog = () => {
  this.setState({ opencompanyDialog: false })
}

navigateToCreateCompany = () => {
  const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
  navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCompanyDetails");
  navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigateMsg);
}

companyApiResponse = (responseJson: ShowCompanyData) => {
  if (responseJson && !responseJson.error) {
      this.setState({ companyName: responseJson.organisation_details?.data?.attributes.company_name || "", loader:false })
  }
}

   // Customizable Area End
}