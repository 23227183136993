import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData , removeStorageData, setStorageData} from '../../../framework/src/Utilities';
import { SelectChangeEvent } from '@mui/material';

import React from "react";

interface UserProfile {
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      "photo": string;
      "full_name": string;
      "email": string;
      "linkedin": string | null;
      "twitter": string | null;
      "website": string | null;
      "date_of_birth": string | null;
    }
  }
}

interface CountryCodes {
  country_name: string;
  country_code: string;
  region: string;
}
interface ContryCodeResponse {
  message?: string;
  error?: string;
  data?: CountryCodes[];
}

interface DeleteProfilePhoto {
  error?: string;
  message?: string;
}

interface Touched {
  firstName?: boolean;
  lastName?: boolean;
  emailForHelp?: boolean;
  helpRequest?: boolean;
  phoneNumber?: boolean;
}

interface Errors {
  firstName: string;
  lastName: string;
  emailForHelp: string;
  helpRequest: string;
  phoneNumber: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  closeLogout: () => void ;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profileSettingScreen: boolean;
  notificationSettingScreen: boolean;
  updateNotification: boolean;
  logoutProfile:boolean;
  deleteAccount:boolean;
  deleteSuccess: boolean;
  changePassword:boolean;
  defineRatingAction:boolean;
  authToken: string;
  newPasswords: string;
  confirmPasswords: string;
  currentPassword: string;
  currentPasswordResponse:boolean;
  ChangedOpen:boolean;
  jobLevelPlusButton:boolean;
  profileId:string;
  talentActiveTab:string;
  defineTab:string;
  userProfileData: UserProfile;
  deleteProfilePhoto: DeleteProfilePhoto;
  imageRef: React.RefObject<HTMLInputElement>;
  imageUpload: FileList | null;
  profileImage: string[];
  referenceImage: string;
  languageSettingScreen: boolean;
  languageChange: string[];
  selectedLanguage: string;
  selectedCountryCode: string;
  languageChangeSuccess: boolean;
  rolespermissionSettingScreen: boolean;
  activeTab: string;
  helpSupportSettingScreen:boolean;
  countryCodeData:ContryCodeResponse;
  helpSupportChangeSuccess: boolean;
  firstName:string;
  lastName:string;
  emailForHelp:string;
  helpRequest:string;
  phoneNumber:string; 
  plusButton:boolean;
  jobFamilyPlusButton:boolean;
  jobFamilyAction:boolean;
  touched: Touched;
  errors: Errors;
  companyDetails: boolean,
  JobDescription: boolean,
  ModelShowEmployeeData: Array<{
    department: string, designation: string, email: string, firstName: string, id: string, lastName: string
  }>,
  HrSubAdminSuccessModelState:boolean,
  manageAllUsers: boolean,
  competencies: boolean,
  CriticalPosition: boolean,
  questionBank: boolean,
  jobLevelAction: boolean,
  assessments: boolean,
  employeeRole: any,
  selectedEmployees: any,
  openSuccessComp:boolean
  selectedRoleId:any,
  candidateRole: any,
  selectedCandidate: any,
  selectedCandidateRoleId:any,
  openSuccessCandidateComp:boolean
  employeeDataPopup:any
  candidateDataPopup:any

  talentProfileSettingScreen:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgetPasswordApiCall: string = "";
  getCurrentPassApiCallId: string = "";
  showUserProfileApiCallId: string ="";
  getUserAccountDeleteApiCallId: string ="";
  deleteProfilePhotoApiCallId: string ="";
  updateProfilePhotoApiCallId: string = "";
  languageChangeApiCallId: string ="";
  createhelpSupportApiCallId: string ="";
  hrSubAdminApiCallId: string= "";
  updateHrSubAdminApiCallId: string= "";
  manageEmployeeApiCallId: string= "";
  updatemanageEmployeeApiCallId: string= "";
  manageCandidateApiCallId: string= "";
  updatemanageCandidateApiCallId: string= "";
  getAllCountryCodeApiCallId: string= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profileSettingScreen: true,
      referenceImage: "",
      notificationSettingScreen: false,
      updateNotification:false,
      logoutProfile:false,
      deleteAccount:false,
      deleteSuccess:false,
      changePassword:false,
      imageUpload: null,  
      profileImage: [],
      authToken: "",
      newPasswords: "",
      confirmPasswords: "",
      currentPassword: "",
      currentPasswordResponse:false,
      plusButton:false,
      jobFamilyPlusButton:false,
      jobFamilyAction:false,
      jobLevelAction:false,
      ChangedOpen:false,
      jobLevelPlusButton:false,
      profileId:"",
      userProfileData: {},
      deleteProfilePhoto:{}, 
      countryCodeData:{},
      imageRef: React.createRef(),
      languageSettingScreen:false,
      languageChange:["English" , "عربي"],
      selectedLanguage: "English",
      selectedCountryCode: "+91",
      languageChangeSuccess: false,
      rolespermissionSettingScreen:false,
      activeTab: 'HR SubAdmin',
      talentActiveTab:"DefineRating",
      defineTab:"genRating",
      helpSupportSettingScreen:false,
      helpSupportChangeSuccess:false,
      firstName:"",
      lastName:"",
      emailForHelp:"",
      helpRequest:"",
      phoneNumber:"",
      touched: {},
      errors:{
        firstName:"",
        lastName:"",
        emailForHelp:"",
        helpRequest:"" ,
        phoneNumber:""      
      },
      companyDetails: false,
  JobDescription: false,
  defineRatingAction: false,
  manageAllUsers: false,
  competencies: false,
  CriticalPosition: false,
  questionBank: false,
  assessments: false,
  employeeRole:[],
  selectedEmployees: [],
  openSuccessComp:false,
  selectedRoleId:[],
  candidateRole: [],
  selectedCandidate: [],
  selectedCandidateRoleId:[],
  openSuccessCandidateComp:false,
  employeeDataPopup:[],
  candidateDataPopup:[],
  ModelShowEmployeeData:[],
  HrSubAdminSuccessModelState: false,
  talentProfileSettingScreen:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.forgetPasswordApiCall){
    }
    if(apiRequestCallId === this.getCurrentPassApiCallId){

      this.setState({currentPasswordResponse:responseJson.matched})
    }
    if (apiRequestCallId === this.showUserProfileApiCallId) {
      this.handleUserProfileApiResponse(responseJson)
    }
    if (apiRequestCallId === this.deleteProfilePhotoApiCallId) {
      this.handleDeletePhotoApiResponse(responseJson)
    }
    if(apiRequestCallId === this.updateProfilePhotoApiCallId){
    }
    this.HrSubAdminApicallResponseSet(apiRequestCallId,responseJson)
    this.settingRevieve(apiRequestCallId ,responseJson )
    this.settingRecieve(apiRequestCallId ,responseJson )
    // Customizable Area End

  }
  txtInputWebProps = {
    onChangeText: (text: string) => {this.setState({ txtInputValue: text });
    },secureTextEntry: false,
  };
  txtInputMobileProps = {...this.txtInputWebProps,autoCompleteType: "email", keyboardType: "email-address",};
  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;
 btnShowHideProps = {
    onPress: () => {this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = 
      !this.state.enableField;
      this.btnShowHideImageProps.source = 
      this.txtInputProps.secureTextEntry
        ? imgPasswordVisible: imgPasswordInVisible;
    },};

  btnShowHideImageProps = {source: this.txtInputProps.secureTextEntry ? imgPasswordVisible
      : imgPasswordInVisible,};

  btnExampleProps = { onPress: () => this.doButtonPressed(),};
doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess)); message.addData(
      getName(MessageEnum.AuthTokenDataMessage),this.state.txtInputValue
    ); this.send(message);}

  // web events
  setInputValue = (text: string) => {this.setState({ txtInputValue: text });};

  setEnableField = () => {this.setState({ enableField: !this.state.enableField });};

  // Customizable Area Start

  async componentDidMount() {
    const tokenMeta = await getStorageData('signInResponse', true);
    this.setState({
      authToken: tokenMeta?.meta?.token,
      profileId: tokenMeta?.meta?.profile_id
    })
    this.showUserProfileApiCall()
    this.setState({ activeTab: "HRSubadmin" });
    this.hrSubAdminGetApiCall()
    this.manageEmployeeGetApiCall()
    this.manageCandidateGetApiCall()
    this.getAllCountryCodeApiCall();
  }

  apiResetPasswords = () => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody: any = {
      "password": this.state.newPasswords,
      "password_confirmation": this.state.confirmPasswords

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.forgetPasswordApiCall = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resetPasswordEndPoint}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  apiCurrentPasswordGetCall = (currentPassword: string) => {
    const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentPassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCurrentPassApiEndPoint}=${currentPassword}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showUserProfileApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showUserProfileApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showUserProfileApiEndPoint}/${this.state.profileId}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  userAccountDeleteGetCall = () => {
    
    const headers = {
      "token": this.state.authToken,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAccountDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserAccountDeleteAPiEndPoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteProfilePhotoApiCall = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProfilePhotoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProfilePhotoApiEndpoint}/${this.state.profileId}/delete_photo`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  languageChangeApiCall = () => {
    const headers = {
        "token": this.state.authToken
    };
    const getUserStatusMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.languageChangeApiCallId = getUserStatusMsg.messageId;
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.languageChangeApiEndPoint}=${this.state.selectedLanguage === "English" ? "en" : "ar"}`
    );
    getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

helpSupportCall = async() => {
    
  const headers = {
    "token": this.state.authToken,
    "Content-Type": "application/json",
  };
  const httpBody: any = {
    "help_and_support": {
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "phone_number": this.state.phoneNumber,
      "email": this.state.emailForHelp,
      "help_request": this.state.helpRequest
  }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.createhelpSupportApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createHelpSupportApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
hrSubAdminGetApiCall = () => {
  const headers = {
      "token": this.state.authToken
  };
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.hrSubAdminApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hrsubAdminApiEndPoint}`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

updateHrSubAdminApiCall = () => {
  const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
  };
  const httpBody: any = {
    "setting": {
      "company_details": this.state.companyDetails,
      "job_description": this.state.JobDescription,
      "manage_all_users": this.state.manageAllUsers,
      "competencies": this.state.competencies,
      "critical_positions": this.state.CriticalPosition,
      "question_bank": this.state.questionBank,
      "assessments": this.state.assessments
    }
  }
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updateHrSubAdminApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatehrsubAdminApiEndPoint}`
  );
  getUserStatusMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

HrSubAdminApicallResponseSet = (apiRequestCallId:string, response:{ meta:{message :string} }) =>{
  if (apiRequestCallId === this.updateHrSubAdminApiCallId) {
    if (response.meta.message === "Setting updated successfully.") {
      this.setState({
        HrSubAdminSuccessModelState: true
      })
    }
  }
}

HrSubAdminSuccessModelStateClose = () => {
  this.setState({
    HrSubAdminSuccessModelState: false
  })
}

manageEmployeeGetApiCall = () => {
  const headers = {
      "token": this.state.authToken
  };
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.manageEmployeeApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.manageEmployeeApiEndPoint}employee`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

updateManageEmployeeApiCall = (id: any[]) => {
  const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
  };

  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updatemanageEmployeeApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatemanageEmployeeApiEndPoint}?current_tab=manage_employee&user_ids=[${id}]`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

manageCandidateGetApiCall = () => {
  const headers = {
      "token": this.state.authToken
  };
  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.manageCandidateApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.manageEmployeeApiEndPoint}candidate`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

updateManageCandidateApiCall = (id: any[]) => {
  const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
  };

  const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updatemanageCandidateApiCallId = getUserStatusMsg.messageId;
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatemanageEmployeeApiEndPoint}?current_tab=manage_candidates&user_ids=[${id}]`
  );
  getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
  );
  runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
}

getAllCountryCodeApiCall = () => {
  const headers = {
      "Content-Type": "application/json",
  };
  const getMsgForCountryCode = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAllCountryCodeApiCallId = getMsgForCountryCode.messageId;
  getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryCodeApiEndPoint}`
  );
  getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
  );
  runEngine.sendMessage(getMsgForCountryCode.id, getMsgForCountryCode);
}

  handleUserProfileApiResponse = (responseJson: UserProfile) => {
    if (responseJson) {
      this.setState({ userProfileData: responseJson })
    }
  }

  handleDeletePhotoApiResponse = (responseJson: DeleteProfilePhoto) => {
    if (responseJson && !responseJson.error) {
      
      if(this.state.imageUpload){
        this.setState({
          profileImage: [], imageUpload: null
        })
      }
      this.setState({ deleteProfilePhoto: responseJson },() => {this.showUserProfileApiCall()})
    }
  }

  handleImage = () => {
    return this.state.imageRef.current ? this.state.imageRef.current.click() : ""
  }

  updateProfilePhotoApiCall = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfilePhotoApiCallId = requestMessage.messageId;
    if(this.state.imageUpload && this.state.imageUpload?.length > 0){
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "profile": {
            "full_name": this.state.userProfileData.data?.attributes.full_name,
            "date_of_birth": this.state.userProfileData.data?.attributes.date_of_birth,
            "company_name": this.state.userProfileData.data?.attributes.company_name,
            "website": this.state.userProfileData.data?.attributes.website,
            "linkedin": this.state.userProfileData.data?.attributes.linkedin,
            "twitter": this.state.userProfileData.data?.attributes.twitter,
            "photo": this.state.referenceImage
          }
        })
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserProfileApiEndpoint}/${this.state.profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleImageChange= (files: FileList | null) => {
    if(files){
      this.setState({
        profileImage: [URL.createObjectURL(files[0])],
        imageUpload: files
      })
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.setState({referenceImage: fileReader.result as string}, () => this.updateProfilePhotoApiCall())
      };

      fileReader.readAsDataURL(files[0]);
    }
  }

  removeProfilePhoto = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
     this.deleteProfilePhotoApiCall();
  }

  navigateToDashboard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  profileScreen = () => {
    this.setState({profileSettingScreen : true ,talentProfileSettingScreen:false,notificationSettingScreen : false ,
       languageSettingScreen:false , rolespermissionSettingScreen:false ,helpSupportSettingScreen:false})
  }

  notificationScreen = () => {
    this.setState({notificationSettingScreen : true,talentProfileSettingScreen:false, profileSettingScreen : false ,
       languageSettingScreen:false , rolespermissionSettingScreen:false , helpSupportSettingScreen:false})
  }

  closeNotificationPopupModal = () => {
    this.setState({updateNotification:false});
  };
  
  openNotificationPopupModal = () => {
    this.setState({updateNotification:false});
  };

  handleUpdateNotification = () => {
    this.setState({updateNotification:true});
  }

  closeLogoutProfilePopupModal = () => {
    this.setState({logoutProfile:false});
  };
  
  openLogoutProfilePopupModal = () => {
    removeStorageData("signInResponse")
    this.navigateToSignIn()
    this.setState({logoutProfile:false});
  };

  handleLogoutProfile = () => {
    this.setState({logoutProfile:true});
  }

  navigateToSignIn = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  closeDeleteAccountPopupModal = () => {
    this.setState({deleteAccount:false});
  };
  
  openDeleteAccountPopupModal = () => {
    this.handleDeleteSuccess()
    this.setState({deleteAccount:false});
  };

  handleDeleteAccount = () => {
    this.setState({deleteAccount:true});
  }

  closeDeleteSuccessPopupModal = () => {
    this.setState({deleteSuccess:false});
  };
  
  openDeleteSuccessPopupModal = () => {
    this.setState({deleteSuccess:false});
    this.navigateToEmailLogin();
  };

  handleDeleteSuccess = () => {
    this.userAccountDeleteGetCall();
    this.setState({deleteSuccess:true});
  }

  closeChangePasswordPopupModal = () => {
    this.setState({changePassword:false});
  };
  handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCountryCode: event.target.value });
};
  
  openChangePasswordPopupModal = (password :{currentPassword: string;
    newPassword: string;
    confirmPassword: string
  }) => {
    this.setState({currentPassword:password.currentPassword ,  newPasswords:password.newPassword , confirmPasswords:password.confirmPassword}, () =>this.apiResetPasswords());
    this.setState({ChangedOpen : true ,changePassword:false })

  };
  
  handleChangePassword = () => {
      this.setState({changePassword:true});

  }

  closeChangedModal = () => {
    this.setState({ChangedOpen : false})
  }
  
  openChangedModal = () => {
    this.navigateToEmailLogin()
  }

  navigateToEmailLogin = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  languageScreen = async() => {
    const language = await getStorageData("language");
    this.setState({languageSettingScreen: true ,talentProfileSettingScreen:false, profileSettingScreen:false,
       notificationSettingScreen:false,rolespermissionSettingScreen:false, helpSupportSettingScreen:false, selectedLanguage: language})
  }

  handleLanguageChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedLanguage: event.target.value });
  };

  closelanguageChangeModal = () => {
    this.setState({languageChangeSuccess : false})
  }

  openlanguageChangeModal = () => {
    location.reload();
    this.setState({languageChangeSuccess : false})
  }

  handlelanguageChangeSuccess = () => {
    this.languageChangeApiCall();
    setStorageData("language", this.state.selectedLanguage);
    this.setState({languageChangeSuccess : true})
  }

  handleHelpSupportChangeSuccess = () => {
    this.handleFirstNameBlur();
    this.handleLastNameBlur();
    this.handleEmailBlur();
    this.handlePhoneNumberBlur();
    this.handleHelpRequestBlur();
  
    this.setState((prevState) => prevState, () => {
      const { errors } = this.state;
      const hasErrors =
        errors && !Object.values(errors).every((ele) => ele === "");
  
      if (hasErrors) {
        console.log("Validation errors detected:", errors);
      } else {
        console.log("No validation errors. Proceeding with success flow.");
        this.helpSupportCall();
        this.setState({ helpSupportChangeSuccess: true });
      }
    });
  };
  
  closeHelpSupportModal = () => {
    this.setState({helpSupportChangeSuccess : false})
  }

  openHelpSupportModal = () => {
    this.setState({helpSupportChangeSuccess : false})
  }

  rolesPermissionScreen = () => {
    this.setState({rolespermissionSettingScreen : true , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false, helpSupportSettingScreen:false,talentProfileSettingScreen:false})
  }

  helpSupportScreen = () => {
    this.setState({ helpSupportSettingScreen:true,talentProfileSettingScreen:false, rolespermissionSettingScreen : false , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false})
  }

  handleHRGubAdminSaveSettings = () => {
    this.updateHrSubAdminApiCall()
  };
  handleManageEmployeeSaveSettings = () => {
  this.setState({openSuccessComp:true})
  };
  handleManageCandidateSaveSettings = () => {
    this.setState({openSuccessCandidateComp:true})
  }
  handleTabClick = (tabName:string) => {
    this.setState({ activeTab: tabName });
  };

  handleTalentClick = (tabName:string) => {
    this.setState({ talentActiveTab: tabName });
  };

  handleDefineTab = (tabName:string) => {
    this.setState({ defineTab: tabName });
  };

  handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: e.target.value });
  };
  
  handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: e.target.value });
  };
  
  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailForHelp: e.target.value });
  };
  
  handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ phoneNumber: e.target.value });
  };
  
  handleHelpRequestChange = (e: any) => {
    this.setState({ helpRequest: e.target.value });
  };
  
  handleFirstNameBlur = () => {
    this.setState((prevState: S) => ({
      touched: { ...prevState.touched, firstName: true },
      errors: {
        ...prevState.errors,
        firstName: prevState.firstName.trim() === "" ? "Required field" : ""
      }
    }));
  };
  
  handleLastNameBlur = () => {
    this.setState((prevState: S) => ({
      touched: { ...prevState.touched, lastName: true },
      errors: {
        ...prevState.errors,
        lastName: prevState.lastName.trim() === "" ? "Required field" : ""
      }
    }));
  };
  
  handleEmailBlur = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.setState((prevState: S) => {
      const email = prevState.emailForHelp.trim();
      let errorMessage = "";
  
      if (email === "") {
        errorMessage = "Required field";
      } else if (!emailRegex.test(email)) {
        errorMessage = "Please enter a valid email address.";
      }
  
      return {
        touched: { ...prevState.touched, emailForHelp: true },
        errors: {
          ...prevState.errors,
          emailForHelp: errorMessage,
        },
      };
    });
  };
  
  handlePhoneNumberBlur = () => {
    const numericRegex = /^\d*$/;
    
    this.setState((prevState: S) => {
      const phoneNumber = prevState.phoneNumber.trim();
      let errorMessage = "";
  
      if (phoneNumber === "") {
        errorMessage = "Required field";
      } else if (!numericRegex.test(phoneNumber)) {
        errorMessage = "Contain only numbers.";
      } else if (phoneNumber.length < 6 || phoneNumber.length > 10) {
        errorMessage = "Between 6 and 10 digits.";
      }
  
      return {
        touched: { ...prevState.touched, phoneNumber: true },
        errors: {
          ...prevState.errors,
          phoneNumber: errorMessage,
        },
      };
    });
  };

  handleHelpRequestBlur = () => {
    this.setState((prevState: S) => ({
      touched: { ...prevState.touched, helpRequest: true },
      errors: {
        ...prevState.errors,
        helpRequest: prevState.helpRequest.trim() === ""
          ? "Required field"
          : ""
      }
    }));
  };
  
  handleCompanyDetailsChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ companyDetails: event.target.checked });
  };
  
  handleJobDescriptionChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ JobDescription: event.target.checked });
  };
  
  handleCompetenciesChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ competencies: event.target.checked });
  };
  
  handleCriticalPositionChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ CriticalPosition: event.target.checked });
  };
  
  handleManageAllUsersChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ manageAllUsers: event.target.checked });
  };
  
  handleQuestionBankChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ questionBank: event.target.checked });
  };
  
  handleAssessmentsChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ assessments: event.target.checked });
  };
  
  handleCheckboxChange = (employee:any) => {
    this.setState((prevState) => {
      const { selectedEmployees } = prevState;
      if (selectedEmployees.includes(employee.id)) {
        return {
          selectedEmployees: selectedEmployees.filter((employeeId:any) => employeeId !== employee.id),
          selectedRoleId: [employee.id],
          employeeDataPopup:{}
        };
      } else {
        return {
          selectedEmployees: [...selectedEmployees, employee.id],
          selectedRoleId: [employee.id],
          employeeDataPopup:employee
        };
      }
    });
  };

  handleCheckboxCandidateChange = (candidate:any) => {    
    this.setState((prevState) => {
      const { selectedCandidate } = prevState;
      if (selectedCandidate.includes(candidate.id)) {
        return {
          selectedCandidate: selectedCandidate.filter((candidateId:any) => candidateId !== candidate.id),
          selectedCandidateRoleId: [candidate.id]
        };
      } else {
        return {
          selectedCandidate: [...selectedCandidate, candidate.id],
          selectedCandidateRoleId: [candidate.id]
        };
      }
    });
    this.HandelSelectEmplyData(candidate)
  };

  HandelSelectEmplyData = (employee: {
    department: string;
    designation: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
  }) => {
    const  { ModelShowEmployeeData } = this.state;
    if (ModelShowEmployeeData.map((i) => i.id).includes(employee.id)) {
      this.setState({
        ModelShowEmployeeData: this.state.ModelShowEmployeeData.filter((item: { id: string }) => `${item.id}` !== `${employee.id}`)
      }) 
    }else{
      this.setState({
        ModelShowEmployeeData: this.state.ModelShowEmployeeData.concat(employee)
      }) 
    }
  }



  settingRevieve = (apiRequestCallId:string, responseJson : any) => {
  if (apiRequestCallId === this.hrSubAdminApiCallId) {
    
    const hrSubAdminData = responseJson.data?.attributes;
  
    if (hrSubAdminData) {    
      this.setState({
        companyDetails: hrSubAdminData.company_details,
        JobDescription: hrSubAdminData.job_description,
        manageAllUsers: hrSubAdminData.manage_all_users,
        competencies: hrSubAdminData.competencies,
        CriticalPosition: hrSubAdminData.critical_positions,
        questionBank: hrSubAdminData.question_bank,
        assessments: hrSubAdminData.assessments,
      });
    } 
  }
  if(apiRequestCallId === this.createhelpSupportApiCallId){
    if(responseJson){this.setState({
        firstName:"",lastName:"",helpRequest:"",phoneNumber:"",emailForHelp:""})
    } }
  if(apiRequestCallId === this.manageEmployeeApiCallId){
    if(responseJson?.message){
      return this.setState({
        employeeRole: []
      })
    }
    const manageEmployeeData = responseJson.data.map((data:any) => ({
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      designation: data.designation,
      department:data.department,
      email:data.email
  }));
  
    this.setState({employeeRole:manageEmployeeData })
  }
}

settingRecieve = (apiRequestCallId:string, responseJson : any) => {
  if(apiRequestCallId === this.updatemanageEmployeeApiCallId){
    if(responseJson?.message){
      this.manageEmployeeGetApiCall();
      this.setState({
        selectedRoleId: [],
        selectedEmployees: []
      })
    }
  }
  if(apiRequestCallId === this.manageCandidateApiCallId){
    console.log("responseJson",responseJson)
    if(responseJson?.message){
      return this.setState({
        candidateRole: []
      })
    }
    const manageCandidateData = responseJson.data.map((data:any) => ({
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      designation: data.designation,
      department:data.department,
      email:data.email
  }));
  
    this.setState({candidateRole:manageCandidateData })
  }
  if(apiRequestCallId === this.updatemanageCandidateApiCallId){
    if(responseJson?.message){
      this.manageCandidateGetApiCall();
      this.manageEmployeeGetApiCall();
      this.setState({
        selectedRoleId: [],
        selectedEmployees: [],
        ModelShowEmployeeData:[]
      })
    }
  }
  if (apiRequestCallId === this.getAllCountryCodeApiCallId) {
    console.log("responseJosn",responseJson)
    this.countryCodeApiResponse(responseJson)
}
}

  handleChangeRole = (data:any) => {
    this.setState({openSuccessComp:true , selectedRoleId:data.id , employeeDataPopup:data})
  }

  handleChangeCandidateRole = (data:any) => {
    this.setState({openSuccessCandidateComp:true , selectedCandidateRoleId:data.id , candidateDataPopup:data})
  }

  countryCodeApiResponse = (responseJson: ContryCodeResponse) => {
    if (responseJson && !responseJson.error) {
        this.setState({ countryCodeData: responseJson })
    }
}

  handleCloseSuccessComp = () => {
    this.setState({openSuccessComp:false})
  }

  handleOpenSuccessComp = () => {
    console.log("selectedEmployees", this.state.selectedEmployees, this.state.selectedRoleId)
    if(this.state.selectedEmployees?.length > 1){
      this.updateManageEmployeeApiCall([this.state.selectedEmployees])
    }else{
      this.updateManageEmployeeApiCall([this.state.selectedRoleId])
    }
    this.setState({openSuccessComp:false})
  }

  handleCloseCandidateSuccessComp = () => {
    this.setState({openSuccessCandidateComp:false})
  }

  handleOpenCandidateSuccessComp = () => {
    if(this.state.selectedCandidate?.length > 1){
      this.updateManageCandidateApiCall([this.state.selectedCandidate])
    }else{
      this.updateManageCandidateApiCall([this.state.selectedCandidateRoleId])
    }
    this.setState({openSuccessCandidateComp:false})
  }
  talentProfileScreen = () => {
    this.setState({ talentProfileSettingScreen:true ,helpSupportSettingScreen:false, rolespermissionSettingScreen : false , languageSettingScreen: false , 
      profileSettingScreen:false, notificationSettingScreen:false})
  }

  handleAddLevel = () => {
    this.setState({ plusButton:true})
  }
  handleAddJobFamily = () => {
    this.setState({ jobFamilyPlusButton:true})
  }
  handleAction=()=>{
    this.setState((prevState) => ({
      jobFamilyAction: !prevState.jobFamilyAction,
    }));
  }
  handleDefineLevel=()=>{
    this.setState({
      jobLevelAction: !this.state.jobLevelAction,
    });
  }
  handleDefineRatingAction=()=>{
    this.setState((prevState) => ({
      defineRatingAction: !prevState.defineRatingAction,
    }));
  }
  handleAddJobLevel = () => {
    this.setState({ jobLevelPlusButton:true})
  }
  
  closeLevelPop = () => {
    this.setState({ jobLevelAction:false})
  }

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
}

closeListIconPopup = () => {
  this.setState({ jobFamilyAction: false });
};
closedefineRatPop = () => {
  this.setState({ defineRatingAction: false });
};
closeDataProps =()=>{
  this.setState((perv)=>({
    jobFamilyAction: !perv.jobLevelAction
  }));
}

  // Customizable Area End
}
