import React, { useState } from "react";
import { Box, Typography, Button, styled,Checkbox } from "@mui/material";
import { closeIcon, right } from "./assets";

interface Props {
    onClose: () => void;
    headingPopup:String; 
    titlePopup?:String; 
    subTitlePopup : String;
    cancelButtonText?: String;
    buttonText:String;
    onOpen: () => void;
    showCancelButton?: boolean;
    successIcon?: string;
    checkBox?: boolean;
    deleteSuccess?:boolean;
    checkBoxTitlePopup?: string;
    requiredCheckBoxError?: string;
}

const CustomPopup = (props: Props) => {
    const { onClose, headingPopup, titlePopup,deleteSuccess, successIcon,checkBox,checkBoxTitlePopup,requiredCheckBoxError, subTitlePopup,cancelButtonText, buttonText, onOpen, showCancelButton = false } = props;
    
    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);

  const handleButtonClick = () => {
    if (checkBox && !isChecked) {
      setShowError(true);
    } else {
      setShowError(false);
      onOpen();
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
    if (showError) setShowError(false);
  };

    return (
        <MainWrapper>
            <Box style={{width:"100%"}}>
            <Box className="paper">
                <Box className="mainLayout">
                    <Typography className="headerTitle">{headingPopup}</Typography>
                    <Box className="closeIconBox" onClick={onClose}>
                        <img src={closeIcon.default} className="closeIcon" />
                    </Box>
                </Box>
                <Box className="divider"></Box>
                <Box className="success">
                    <img src={successIcon ? successIcon : right.default} />
                </Box>
                <Box className="boxLayout">

                    <Typography className="content1">
                    {titlePopup}
                </Typography>
                {deleteSuccess ? 
                <Typography className="deleteSuccess">
                {subTitlePopup}
            </Typography>
            :
            <Typography className={checkBox ? "contentCheckbox"  : "content2"}>
                        {subTitlePopup}
                    </Typography>
            }
                    
                    {checkBox && (
                        <Box>
                        <Box display={"flex"} alignItems={"center"}>
                        <Checkbox checked={isChecked}
                            onChange={handleCheckboxChange}
                            sx={{
                            color: showError ? "#D90000" : undefined,
                            "&.Mui-checked": {
                                color: showError ? "#D90000" : "#0F172A",
                            },
                            }}/>
                        <Typography className="content2">
                            {checkBoxTitlePopup}
                        </Typography>
                        </Box>
                        {showError && (
                  <Box display={"flex"} justifyContent={"end"}>
                    <Typography className="requiredcontent">
                      {requiredCheckBoxError}
                    </Typography>
                  </Box>
                )}
                        </Box>
                    )}
                </Box>
                <Box className="divider"></Box>
                <Box className="popupContent">
                {showCancelButton && (
                            <Button data-test-id="cancel-btn" className="cancelbutton" onClick={onClose}>
                                {cancelButtonText || "Cancel"}
                            </Button>
                        )}
                    <Button data-test-id="move-btn" className="button" onClick={checkBox ? handleButtonClick :  onOpen}>
                        {buttonText}
                    </Button>
                </Box>
            </Box>
            </Box>
        </MainWrapper>
    );
};

export default CustomPopup;

export const MainWrapper = styled(Box)(({ theme }) => ({
    "& .paper": {
        marginTop: "55px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "600px",
        backgroundColor: "#fff",
        borderRadius: "8px 8px 32px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "24px",
    },
    "& .mainLayout": {
        width: "92%",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "24px",
    },
    "& .headerTitle": {
        color: "#000000",
        fontFamily: "Urbanist",
        fontSize: "24px",
        fontWeight: "700",
        display: "flex",
        alignItems: "center",
    },
    "& .closeIconBox": {
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .closeIcon": {
        width: "14px",
        height: "14px",
    },
    "& .success": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "22px",
    },
    "& .boxLayout": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        padding: "0 40px",
    },
    "& .content1": {
        textAlign:"center",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: "700",
    },
    "& .content2": {
        textAlign:"center",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: "400",
    },
    "& .deleteSuccess": {
        textAlign:"center",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: "700",
    },
    "& .requiredcontent": {
        textAlign:"center",
        color: "#F87171",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: "400",
    },
    "& .contentCheckbox": {
        textAlign:"center",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: "400",
        padding: "0 60px"
    },
    "& .popupContent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "end",
        padding: "0 22px 22px 0",
        gap: "15px",
    },
    "& .button": {
        padding: "16px 30px",
        backgroundColor: "#044352",
        color: "#FFFFFF",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "700",
        textTransform:"none",
        width:"120px",
        borderRadius:"8px",
        "&:hover": {
            backgroundColor: "#044352",
        }
    },
    "& .cancelbutton": {
        padding: "16px 30px",
        color: "#044352",
        backgroundColor: "#F1F5F9",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "700",
        textTransform:"none",
        width:"120px",
        borderRadius:"8px",
        boxShadow: "0px 4px 4px 0px #00000040",
    },
    "& .divider":{
        width:"100%",
        border:"1px solid #E2E8F0"
    },

    [theme.breakpoints.down('md')]: {
        "& .paper": {
            width: "85%",
            marginTop: "400px",
            padding: "0 10px",
        },
        "& .boxLayout": {
            padding: "0 20px",
        },
        "& .headerTitle": {
            fontSize: "20px",
        },
        "& .content1": {
            fontSize: "18px",
        },
        "& .content2": {
            fontSize: "16px",
        },
        "& .deleteSuccess": {
            fontSize: "18px",
        },
        "& .requiredcontent": {
            fontSize: "16px",
        },
        "& .contentCheckbox": {
            fontSize: "16px",
        },
        "& .button": {
            fontSize: "14px",
            padding: "12px 24px",
        },
    },

    [theme.breakpoints.down('sm')]: {
        "& .paper": {
            width: "90%",
            marginTop: "400px",
            padding: "0 8px",
        },
        "& .boxLayout": {
            padding: "0 16px",
        },
        "& .headerTitle": {
            fontSize: "18px",
        },
        "& .content1": {
            fontSize: "16px",
        },
        "& .content2": {
            fontSize: "14px",
        },
        "& .deleteSuccess": {
            fontSize: "16px",
        },
        "& .requiredcontent": {
            fontSize: "14px",
        },
        "& .contentCheckbox": {
            fontSize: "16px",
        },
        "& .button": {
            fontSize: "12px",
            padding: "10px 20px",
        },
    },
}));
