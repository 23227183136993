Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationDeleteApiMethodType = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatgptintegration9";
exports.labelBodyText = "cfchatgptintegration9 Body";

exports.btnExampleTitle = "CLICK ME";

exports.POST="POST"
exports.createJobDescApiEndPoint= "bx_block_dashboardguests/job_descriptions"
exports.getJobDescLevelApiEndPoint= "bx_block_dashboardguests/job_descriptions/job_level_list"
exports.getJobDescExpApiEndPoint= `bx_block_dashboardguests/job_descriptions/job_experience_list`
exports.getJobDescKeySkillApiEndPoint= `bx_block_dashboardguests/key_skills`
exports.getJobDescJobFamilyAPiEndPoint =`bx_block_dashboardguests/job_descriptions/job_family_list`
exports.getJobDescDetailsAPiEndPoint = `bx_block_dashboardguests/job_descriptions`
exports.getJobDescDetailsDeleteAPiEndPoint = `/bx_block_dashboardguests/job_descriptions`
exports.downloadDropDownData = [
  "Download as a PDF",
  "Download as a Word"
]
exports.getJobDescKeyWordApiEndPoint= `bx_block_dashboardguests/job_descriptions/keywords`
// Customizable Area End

exports.headingPopup = "Under process";
exports.titlePopup = "Congratulations! Your job creation is under process";
exports.subTitlePopup = "Something magical is going to happen.";
exports.buttonText = "Preview";
exports.jobdesGen = "Job Description generator";
exports.genJobDesProgress = "Generating Job Descriptions...";
exports.ProgressheadingPopup = "Job Description Created";
exports.subTitleProgressPopup = "Your new job description has been successfully created and saved in the system.";
exports.buttonTextProgress = "View";
exports.jobDetailsHeading = "Job Description";
exports.headingJobDeletePopup = "Delete Details";
exports.titleJobDeletePopup = "Are you sure you want to delete this  Job Description?";
exports.subTitleJobDeletePopup = "You won’t be able to access it again.";
exports.cancelButtonJobDeletePopup = "Cancel";
exports.buttonJobDeleteText = "Delete";
exports.showCompanyApiEndPoint = "bx_block_dashboardguests/companies/:id";
exports.filterAndSearchApiEndPoint ="/bx_block_dashboardguests/job_descriptions?search=";
exports.getApiMethodType = "GET";
exports.filterAndCalendarApiEndPoint="/bx_block_dashboardguests/job_descriptions?"