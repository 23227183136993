import React,{ReactNode} from "react";

import { Input } from '@builder/component-library';

// Customizable Area Start
import {
  Box,
  Grid,
  styled,
  Card,
  Typography,
  Button,
  CardContent,
  FormControlLabel,
  Switch,
  InputAdornment,
  SwitchProps,
  Divider,
  Modal,
  Select,
  FormControl,
   MenuItem,
  InputBase,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  alpha,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { arrow_Left, chnageRole, defaultIcon, deleteIcon, help, language, logout, notification, profile, right, roles, talentProfile, userDefaultImage } from "./assets";
import { DashboardHeaderWeb as DashboardHeader} from "../../dashboard/src/DashboardHeader.web";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import ChangePasswordModal from "../../../components/src/ChangePasswordModal.web";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
interface BoxProps {
  children: ReactNode;
}

import { View, StyleSheet } from "react-native";

import { imgVisbility, imgVisbilityOff } from "./assets";

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { color } from "react-native-elements/dist/helpers";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  profileTab = () => {
    return (
        <>
            <img
                src={profile.default}
                alt="ProfileSetting"
                className="menuImg" />
                <Grid item >
            <Typography className='menuProfileTitle'>
                Profile Setting
            </Typography>
            <Typography marginTop={"10px"} className="menuSubTitle">
                Settings related to your personal information and account credentials
            </Typography>
                </Grid>
        </>
    ) 
}
notificationTab = () => {
    return (
        <>
        <img
          src={notification.default}
          alt="notificationSetting"
          className="menuImg" />
        <Grid item >
          <Typography className='menuTitle'>
            Notification Settings
          </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle">
          All settings related to notifications
          </Typography>
        </Grid>
        </>
    )
}
languageTab = () => {
    return (
        <>
            <img
                src={language.default}
                alt="languageSettings"
                className="menuImg" />
                <Grid item >
            <Typography className='menuTitle'>
            {configJSON.languageSettings}
            </Typography>
            <Typography marginTop={"10px"} className="menuSubTitle">
            {configJSON.manageLanguages}
            </Typography>
                </Grid>
        </>
    )
}
rolesPermissionTab = () => {
    return (
        <>
              <img
                src={roles.default}
                alt="rolesPermissionSetting"
                className="menuImg" />
                <Grid item >
            <Typography className='menuTitle'>
              Roles & Permissions
            </Typography>
            <Typography marginTop={"10px"} className="menuSubTitle">
            Manage your roles & permissions
            </Typography>
                </Grid>
        </>
    )
}
talentProfileTab = () => {
    return (
        <>
            <img
                src={talentProfile.default}
                alt="talentProfileSetting"
                className="menuImg" />
                <Grid item >
            <Typography className='menuTitle'>
            Talent Profile
            </Typography>
            <Typography marginTop={"10px"} className="menuSubTitle">
            Manage your roles & permissions
            </Typography>
                </Grid>
        </>
    )
}
helpTab = () => {
    return (
        <>
           <img
                src={help.default}
                alt="helpSetting"
                className="menuImg" />
                <Grid item >
            <Typography className='menuTitle'>
            Help and Support
            </Typography>
            <Typography marginTop={"10px"} className="menuSubTitle">
            Access FAQs and different policies
            </Typography>
                </Grid>
        </>
    )
}

profileSettingScreen = () => {
  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5} className="profilesettings">
    <Grid container justifyContent={"space-between"} className="jobMainHeaderGrid">
      <Grid item flexWrap={"wrap"}>
        <Box >
          <Typography className="profileText">
            Your Photo
          </Typography>
        </Box>
      </Grid>
      <Grid item marginRight={"80px"} xs={3} md={2.5} xl={2}>
        <Box className="profileImageMain">
          <Box className="profileImage">
            {this.state.imageUpload ? <img src={this.state.profileImage[0]}
            style={{borderRadius:"50%"}}
            height={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"}
            width={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"} /> : <img src={this.state.userProfileData.data?.attributes.photo ? 
            this.state.userProfileData.data?.attributes.photo : 
            userDefaultImage.default}
            style={{borderRadius:"50%"}}
            height={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"}
            width={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"} />}
            <input data-test-id="upload_referenceImage" type="file" accept=".jpeg, .png" style={{display: "none"}} onChange={(event) => {this.handleImageChange(event.target.files)}} ref={this.state.imageRef} />
          </Box>
        </Box>
      </Grid>
      <Grid item className="buttonsprofile" marginRight={"26px"}>
        <Button className="profileButton" data-test-id="removeProfilePhoto" onClick={this.removeProfilePhoto}>
          Delete
        </Button>
        <Button data-test-id="handleImage" className="profileButton" onClick={() => this.handleImage()}>
          Update
        </Button>
      </Grid>
    </Grid>
    <Grid item xs={12} marginTop={"25px"}>
      <Divider
        sx={{
          color: "#CBD5E1",
          marginLeft: '26px',
          marginRight: '26px',
        }}
      />
    </Grid>
    <Grid xs={12} marginTop={"25px"} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Grid item >
          <Typography className="profileText">
            Personal details
          </Typography>
        </Grid>
        <Grid item xs={7.4} className="formField" marginRight={"20px"}>
          <Typography className="formText">
            Full name
          </Typography>
          <input
          className="textfieldStyle"
              data-test-id="fullName"
              required
              name="fullName"
              value={this.state.userProfileData.data?.attributes.full_name}
            />
          <Typography marginTop={"20px"} className="formText">
            Email
          </Typography>
          <input
          className="textfieldStyle"
              data-test-id="email"
              required
              name="email"
              value={this.state.userProfileData.data?.attributes.email}
            />
          <Typography marginTop={"20px"} className="formText">
            Company Name
          </Typography>
          <input
          className="textfieldStyle"
              data-test-id="companyName"
              required
              name="companyName"
              value={this.state.userProfileData.data?.attributes.company_name}
            />
          <Grid item display={"flex"} marginTop={"20px"} alignItems={"start"} justifyContent={"space-between"} flexWrap={"wrap"}>
            <Grid item>
          <Typography className="formText">
            Password
          </Typography>
          <Typography>*********</Typography>
            </Grid>
          <Button className="profileButton"  style={{textDecoration:"underline" , textDecorationColor:"#044352"}}  data-test-id="changePassword" onClick={this.handleChangePassword}>
            Change password
          </Button>
          </Grid>
        </Grid>
    </Grid>
    <Grid item xs={12} marginTop={"35px"}>
      <Divider
        sx={{
          color: "#CBD5E1",
          marginLeft: '26px',
          marginRight: '26px',
        }}
      />
    </Grid>
    <Grid xs={12} marginTop={"25px"} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Grid item >
          <Typography className="profileText">
            Account Actions
          </Typography>
        </Grid>
        <Grid item xs={7.4} className="formField" marginRight={"20px"}>
          <Grid item display={"flex"} alignItems={"center"} justifyContent={"start"}>
          <img src={logout.default}/>
          <Button className="profileButton" onClick={this.handleLogoutProfile}>
            Logout
          </Button>
          </Grid>
          <Grid item marginTop={"30px"} marginBottom={"47px"} display={"flex"} alignItems={"center"} justifyContent={"start"}>
          <img src={deleteIcon.default}/>
          <Button className="deleteButton" onClick={this.handleDeleteAccount}>
            Delete account
          </Button>
            </Grid>
        </Grid>
    </Grid>
    <Modal
        open={this.state.logoutProfile}
        onClose={this.closeLogoutProfilePopupModal}>
        <CustomPopup
          data-test-id="logoutProfilePopupModal"
          headingPopup={configJSON.headingLogoutProfilePopup}
          titlePopup={configJSON.titleLogoutProfilePopup}
          subTitlePopup={configJSON.subTitleLogoutProfilePopup}
          cancelButtonText = {configJSON.cancelButtonLogutProfilePopup}
          buttonText={configJSON.buttonLogoutProfileText}
          successIcon={defaultIcon.default}
          showCancelButton={true}
          onOpen={this.openLogoutProfilePopupModal}
          onClose={this.closeLogoutProfilePopupModal} />
      </Modal>
      <Modal
        open={this.state.deleteAccount}
        onClose={this.closeDeleteAccountPopupModal}>
        <CustomPopup
          data-test-id="deleteAccountPopupModal"
          headingPopup={configJSON.headingDeleteAccountPopup}
          titlePopup={configJSON.titleDeleteAccountPopup}
          subTitlePopup={configJSON.subTitleDeleteAccountPopup}
          checkBox={true}
          checkBoxTitlePopup={configJSON.checkBoxPopupText}
          requiredCheckBoxError={configJSON.requiredCheckText}
          cancelButtonText = {configJSON.cancelButtonDeleteAccountPopup}
          buttonText={configJSON.buttonDeleteAccountText}
          successIcon={defaultIcon.default}
          showCancelButton={true}
          onOpen={this.openDeleteAccountPopupModal}
          onClose={this.closeDeleteAccountPopupModal} />
      </Modal>
      <Modal
        open={this.state.deleteSuccess}
        onClose={this.closeDeleteSuccessPopupModal}>
        <CustomPopup
          data-test-id="deleteSuccessPopupModal"
          headingPopup={configJSON.headingDeleteSuccessPopup}
          subTitlePopup={configJSON.subTitleDeleteSuccessPopup}
          deleteSuccess={true}
          buttonText={configJSON.buttonDeleteSuccessText}
          successIcon={right.default}
          showCancelButton={false}
          onOpen={this.openDeleteSuccessPopupModal}
          onClose={this.closeDeleteSuccessPopupModal} />
      </Modal>
      <Modal
        open={this.state.changePassword}
        onClose={this.closeChangePasswordPopupModal}>
        <ChangePasswordModal
          data-test-id="changePasswordPopupModal"
          headingPopup={configJSON.headingChangePasswordPopup}
          currentPasswordTitle={configJSON.titleChangePasswordPopup}
          currentPasswordError={configJSON.errorChangePasswordPopup}
          newPasswordTitle={configJSON.titlenewPasswordPopup}
          newPasswordError = {configJSON.errorNewPasswordPopup}
          confirmPasswordTitle={configJSON.titleconfirmPasswordPopup}
          confirmPasswordError = {configJSON.errorConfirmPasswordPopup}
          cancelButtonText={configJSON.cancelButtonChangePassword}
          buttonText={configJSON.buttonChangePasswordText}
          onOpen={this.openChangePasswordPopupModal}
          onClose={this.closeChangePasswordPopupModal} 
          handlCurrentApi={this.apiCurrentPasswordGetCall}
          handleCurrentResponse = {this.state.currentPasswordResponse}
          />
      </Modal>
      <Modal      
        open={this.state.ChangedOpen}
        onClose={this.closeChangedModal}>
        <CustomPopup 
        data-test-id="custom-popup-changed"
        headingPopup={configJSON.ChangedheadingPopup} 
        titlePopup={configJSON.successTitleChanged} 
        subTitlePopup = {configJSON.subTitleChangedPopup}
        buttonText={configJSON.buttonTextChanged}
        onOpen={this.openChangedModal}
        onClose={this.closeChangedModal}/>
      </Modal>
  </Grid>
  )
}

notificationSettingScreen = () => {
  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5} className="notificationsettings" display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Grid>
    <Grid container  
    justifyContent={"space-between"} className="notificationGrid">
       <Grid item>
       <Box marginLeft={"24px"}>
          <Typography className="notificationTitle"> New Job Alert Notification </Typography>
          <Typography className="notificationSubTitle">Stay informed about the latest job opportunities within the organisation. </Typography>
        </Box></Grid>
      <Grid item><Box marginRight={"24px"} className="switchButton">
        <FormControlLabel  control={<IOSSwitch data-test-id="switchButton1" sx={{ m: 1 }} defaultChecked />}
        label=""/>
        </Box></Grid>
      <Grid item xs={12} marginTop={"36px"}><Divider  sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px'}} 
       />
    </Grid>
    </Grid>
    <Grid container  justifyContent={"space-between"} className="notificationGrid">
      <Grid item><Box marginLeft={"24px"}>
          <Typography className="notificationTitle">Assessment Reminders</Typography>
          <Typography className="notificationSubTitle">Receive timely reminders for all assessments.</Typography>
        </Box>
      </Grid>
      <Grid item> <Box marginRight={"24px"} className="switchButton">
        <FormControlLabel
          control={<IOSSwitch data-test-id="switchButton2" sx={{ m: 1 }} />} label=""/>
        </Box></Grid>
      <Grid item xs={12} marginTop={"36px"}>
      <Divider sx={{
          color: "#CBD5E1",
          marginLeft: '26px',
          marginRight: '26px',
    }} />
    </Grid>
    </Grid>
    <Grid container justifyContent={"space-between"} className="notificationGrid">
  <Grid item>
        <Box marginLeft={"24px"}>
          <Typography className="notificationTitle">Updates from the Organisation
          </Typography>
          <Typography className="notificationSubTitle">Keep up with the latest news, policies, and announcements from the organisation.
          </Typography>
        </Box></Grid>
      <Grid item>
      <Box marginRight={"24px"} className="switchButton">
        <FormControlLabel control={<IOSSwitch data-test-id="switchButton3" sx={{ m: 1 }} defaultChecked />}
          label=""
        /></Box>
      </Grid>
      <Grid item xs={12} marginTop={"36px"}> <Divider
        sx={{
          color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',
        }}
      />
    </Grid> </Grid>
  </Grid>
  <Grid container>
    <Grid item xs={12} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"}>
      <Button  onClick={this.handleUpdateNotification}
        className="notificationButton"  style={{ width: "100%", paddingTop:"20px", paddingBottom:"20px"}} 
      >{configJSON.saveButtonSetting}
      </Button> </Grid></Grid>
      <Modal
        open={this.state.updateNotification}
        onClose={this.closeNotificationPopupModal}>
        <CustomPopup
          data-test-id="NotificationPopupModal"
          headingPopup={configJSON.headingNotificationPopup}
          titlePopup={configJSON.titleNotificationPopup}
          subTitlePopup={configJSON.subTitleNotificationPopup}
          buttonText={configJSON.buttonNotificationText}
          successIcon={right.default}
          showCancelButton={false}
          onOpen={this.openNotificationPopupModal}
          onClose={this.closeNotificationPopupModal} />
      </Modal>
    </Grid>
  )
}

languageSettingScreen = () => {
  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5} className="notificationsettings" display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
    <Grid>
  <Grid container className="notificationGrid">
    <Grid item>
      <Box marginLeft={"24px"}>
        <Typography className="notificationTitle">
        Language Settings
        </Typography>
        <Typography className="notificationSubTitle" marginTop={"10px"}>
        Select your preferred language to customize the platform experience to your liking.
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} marginTop={"36px"}><Divider
      sx={{color: "#CBD5E1",marginLeft: '26px',
      marginRight: '26px',
      }} 
     />
  </Grid></Grid>
  <Grid container  className="notificationGrid" >
    <Grid item  xs={7.5}>
      <Box marginLeft={"24px"} >
        <Typography className="languageTitle" >Choose Your Language:</Typography>
        <Select
            sx={{
              "& .MuiSelect-icon": {
                transform: 'none !important',
              },
            }}
            data-test-id="selectedLanguage"
            className={`dropDownStyle`}
            variant="outlined"
            name="selectedLanguage"
            value={this.state.selectedLanguage}
            displayEmpty
            onChange={this.handleLanguageChange}
            IconComponent={KeyboardArrowRightIcon}
            input={<InputBase />}
        >
            {this.state.languageChange?.map((option : any , index:number) => (
                <MenuItem key={index} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
      </Box>
    </Grid>  
  </Grid>
</Grid>
<Grid container>
  <Grid item xs={12} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"} className="savebutton">
    <Button onClick={this.handlelanguageChangeSuccess} data-test-id="languagebutton"
      className="notificationButton"  style={{ width: "100%", paddingTop:"20px", paddingBottom:"20px"}} 
    >{configJSON.saveButtonSetting}
    </Button> </Grid></Grid>
    <Modal      
        open={this.state.languageChangeSuccess}
        onClose={this.closelanguageChangeModal}>
        <CustomPopup 
        data-test-id="languageChangeSuccess"
        headingPopup={configJSON.languageheadingPopup} 
        titlePopup={configJSON.successlanguageTitleChanged} 
        subTitlePopup = {`You have successfully changed the language to ${this.state.selectedLanguage}.`}
        buttonText={configJSON.buttonlanguageTextChanged}
        onOpen={this.openlanguageChangeModal}
        onClose={this.closelanguageChangeModal}/>
      </Modal>
  </Grid>
  )
}

rolespermissionSettingScreen = () => {

  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5} className="notificationsettings" display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Grid>
      <Grid container  justifyContent={"space-between"} className="notificationGrid">
      <Grid container display={"flex"} gap={'1rem'} className="headerTab" alignItems={"center"} flexWrap={"wrap"} sx={{width:"100%",paddingX:"26px",textAlign:"center"}}>
      <Box
              data-test-id="HRSubadmin"
              onClick={() => this.handleTabClick("HRSubadmin")}
              className={this.state.activeTab === "HRSubadmin" ? "defineRatingTabs" : ""}
            >
              <Typography className={this.state.activeTab === "HRSubadmin" ? "rolesTitle" : "disableText"}>HR SubAdmin</Typography>
            </Box>

            <Box
            data-test-id="ManageEmployeeData"
              onClick={() => this.handleTabClick("ManageEmployeeData")}
              className={this.state.activeTab === "ManageEmployeeData" ? "defineRatingTabs" : ""}
            >
              <Typography className={this.state.activeTab === "ManageEmployeeData" ? "rolesTitle" : "disableText"}>Manage Employee Data</Typography>
            </Box>

            <Box
              data-test-id="ManageCandidates"
              onClick={() => this.handleTabClick("ManageCandidates")}
              className={this.state.activeTab === "ManageCandidates" ? "defineRatingTabs" : ""}
            >
              <Typography className={this.state.activeTab === "ManageCandidates" ? "rolesTitle" : "disableText"}>Manage Candidate</Typography>
            </Box>
      </Grid> 

      <Grid item xs={12} marginTop={"36px"}><Divider
        sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px',
        }} 
       />
    </Grid>
    </Grid>
{
  this.state.activeTab === "HRSubadmin" && 
  <>
    <Grid container  justifyContent={"space-between"} className="notificationGrid">
      <Grid item>
        <Box marginLeft={"24px"}>
          <Typography className="notificationTitle">
          Permissions for the HR SubAdmin settings
          </Typography>
          <Typography className="notificationSubTitle">
          Select your preferred permissions to customise the platform experience to your liking.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} marginTop={"36px"}><Divider
        sx={{color: "#CBD5E1",marginLeft: '26px', marginRight: '26px',}} />
    </Grid>
    </Grid>
    <Grid container  
    justifyContent={"space-between"} 
    className="notificationGrid">
      <Grid item><Box marginLeft={"24px"}>
          <Typography className="notificationTitle"> Company Details
          </Typography>
          <Typography className="notificationSubTitle"> Allows access of the organisation's core information, including company profile and contact details
          </Typography>
        </Box></Grid>
      <Grid item>
        <Box marginRight={"24px"} className="switchButton">
        <IOSSwitch
        sx={{ m: 1 }}
          checked={this.state.companyDetails}
          name="checkedC"
          data-test-id="switch2"
          onChange={this.handleCompanyDetailsChange}
        />
        </Box>
        </Grid>
      <Grid item xs={12} marginTop={"36px"}><Divider sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px',}} 
       />
    </Grid> </Grid>
    <Grid container  justifyContent={"space-between"} 
    
   className="notificationGrid">   <Grid item> <Box marginLeft={"24px"}>
          <Typography className="notificationTitle"> Job Description
          </Typography>
          <Typography className="notificationSubTitle"> 
          Enables the creation, editing, and management of job descriptions for various roles within the company.
          </Typography>
        </Box> </Grid>
      <Grid item>
      <Box marginRight={"24px"} className="switchButton">
                <IOSSwitch
        sx={{ m: 1 }}
        name="checkedC"
        data-test-id="switch3"
        checked={this.state.JobDescription}
      onChange={this.handleJobDescriptionChange}
        />
        </Box></Grid>
      <Grid item xs={12} marginTop={"36px"}><Divider sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px', }} 
       />
    </Grid>
    </Grid>
    <Grid container  justifyContent={"space-between"} className="notificationGrid">
      <Grid item>
        <Box marginLeft={"24px"}>
          <Typography className="notificationTitle">
          Competencies
          </Typography>
          <Typography className="notificationSubTitle">
          Grants the ability to define, assign, and manage competencies required for different job roles.
          </Typography>
        </Box>
      </Grid>
      <Grid item>
      <Box marginRight={"24px"} className="switchButton">
        <IOSSwitch
        data-test-id="switch4"
        sx={{ m: 1 }} name="checkedC"
        onChange={this.handleCompetenciesChange}
        checked={this.state.competencies}
        />
        </Box></Grid>
      <Grid item xs={12} marginTop={"36px"}><Divider
        sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px',
        }} 
       />
    </Grid></Grid>
    <Grid container  justifyContent={"space-between"} className="notificationGrid">
      <Grid item>
        <Box marginLeft={"24px"}> <Typography className="notificationTitle">
        Critical Positions
          </Typography> <Typography className="notificationSubTitle">
          Management of critical positions based on strategic impact, financial impact, and other key factors.
          </Typography>
        </Box></Grid>
      <Grid item>
      <Box marginRight={"24px"} className="switchButton"> 
      <IOSSwitch
        data-test-id="switch5" sx={{ m: 1 }} name="checkedC"
        checked={this.state.CriticalPosition}
        onChange={this.handleCriticalPositionChange}
        />
        </Box></Grid>
      <Grid item xs={12} marginTop={"36px"}><Divider
        sx={{color: "#CBD5E1",marginLeft: '26px',marginRight: '26px',
        }} 
       />
    </Grid>
    </Grid>
        <Grid container justifyContent={"space-between"} className="notificationGrid">
          <Grid item>
            <Box marginLeft={"24px"}>
              <Typography className="notificationTitle">
              All Users
              </Typography>
              <Typography className="notificationSubTitle">
              Provides access to view, manage, and update user information.
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box marginRight={"24px"} className="switchButton">

<IOSSwitch
        data-test-id="switch6" sx={{ m: 1 }} name="checkedC"
        checked={this.state.manageAllUsers} onChange={this.handleManageAllUsersChange}
        />
              
            </Box></Grid>
          <Grid item xs={12} marginTop={"36px"}><Divider
            sx={{
              color: "#CBD5E1", marginLeft: '26px',
              marginRight: '26px',
            }}
          />
          </Grid></Grid>
        <Grid container justifyContent={"space-between"} className="notificationGrid">
          <Grid item><Box marginLeft={"24px"}>
            <Typography className="notificationTitle">Question Bank</Typography>
            <Typography className="notificationSubTitle">
            Allows the creation, editing, and organization of questions for assessments, including the ability to
            </Typography>
             <Typography className="notificationSubTitle">
             set difficulty levels and competencies.</Typography>
          </Box>
          </Grid>
          <Grid item> <Box marginRight={"24px"} className="switchButton">
      <IOSSwitch
        data-test-id="switch7" 
        sx={{ m: 1 }} name="checkedC"
        onChange={this.handleQuestionBankChange}
        checked={this.state.questionBank}
        />
          </Box></Grid>
      <Grid item 
      xs={12} marginTop={"36px"}>
      <Divider sx={{
      color: "#CBD5E1",
          marginLeft: '26px', marginRight: '26px',
    }} />
    </Grid>
    </Grid>
    <Grid container justifyContent={"space-between"}
     className="notificationGrid">
     <Grid item>
        <Box marginLeft={"24px"}>
          <Typography className="notificationTitle">Assessments
    </Typography>
          <Typography className="notificationSubTitle">
          Enables the generation, assignment, and review of assessments, including scoring and result analysis.
      </Typography>
        </Box></Grid>
      <Grid item> <Box marginRight={"24px"} className="switchButton">
          <IOSSwitch
        data-test-id="switch8" 
        sx={{ m: 1 }} 
        name="checkedC"
        checked={this.state.assessments}
        onChange={this.handleAssessmentsChange}
        />
          </Box>
      </Grid>
      <Grid item xs={12} marginTop={"36px"}> <Divider
        sx={{
          color: "#CBD5E1", marginLeft: '26px', marginRight: '26px', }}
      />
    </Grid> 
    </Grid>
    {this.SuccessSettingDialogChange()}
  <Grid container>
    <Grid item xs={12} marginTop={"50px"} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"}>
      <Button data-test-id="handleHRGubAdminSaveSettings" onClick={this.handleHRGubAdminSaveSettings}
        className="notificationButton"  style={{ width: "100%", paddingTop:"20px", paddingBottom:"20px"}} 
      >{configJSON.saveButtonSetting}
      </Button> </Grid>
      </Grid>
      </>
}
{
  this.state.activeTab === "ManageEmployeeData" && 
  <>
            <Grid container className="notificationGrid">
              <Search style={{
                flex: 1, border: "1px solid #94A3B8", display: "flex", flexDirection: "row-reverse",
                marginLeft: "26px", marginRight: "26px"
              }}>
                <SearchIconWrapper> <SearchIcon /> </SearchIconWrapper>
                <StyledInputBase placeholder="Search Employee" fullWidth inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
              <TableContainer 
              sx={{ marginLeft: "26px", marginRight: "26px", marginTop: "26px" }}>
                <Table> <TableHead>
                    <TableRow>
                      <TableCell className="tableDataText">Employee Name</TableCell>
                    <TableCell align="center" className="tableDataText">Designation</TableCell>
                      <TableCell align="right" className="tableDataText">Department</TableCell>
                       <TableCell align="center" sx={{ paddingLeft: "8rem" }} className="tableDataText">
                       Action
                       </TableCell></TableRow>
                  </TableHead>
      <TableBody>
        {this.state.employeeRole?.map((data:any) => (
          <>
                    <TableRow>
                       <TableCell className="tableDataText">
                        <Checkbox 
                        data-test-id="checkbox"
                        checked={this.state.selectedEmployees.includes(data.id)}
                        onChange={() => this.handleCheckboxChange(data)}
                        sx={{ width: "20px",height: "20px", marginRight: "40px",}}/>
                        {data.firstName + " " + data.lastName}
                      </TableCell>
                      <TableCell align="center" 
                      className="tableDataText">
                        {data.designation}
                        </TableCell>
                      <TableCell 
                      align="right" className="tableDataText">
                        {data.department}</TableCell>
                      <TableCell align="right"><Button
                      data-test-id="rolechangeButton"
                        disabled={!this.state.selectedEmployees.includes(data.id) || this.state.selectedEmployees?.length > 1}
                      onClick={() => this.handleChangeRole(data)}
                          sx={{
                            border: "1px solid #94A3B8",
                             padding: "10px 16px",textTransform: "none",
                           color: "#475569", fontFamily: "Urbanist",
                             fontSize: "14px", fontWeight: "700",
                             lineHeight: "22px", }}
                             >
                          Change to HR SubAdmin</Button>
                      </TableCell>
                    </TableRow> 
          </>
        ))}
                  </TableBody> 
                  </Table>
               </TableContainer>
            </Grid>
            {
              this.state.employeeRole?.length !== 0 &&
              <Grid container justifyContent="center" sx={{ mt: 3 }}>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Pagination count={Math.ceil(this.state.employeeRole?.length / 5)}
                    color="primary" siblingCount={0}
                    boundaryCount={1} />
                </Box>
              </Grid>
            } 
            <Grid container>
              <Grid item xs={12} 
              marginTop={"50px"}
               marginLeft={"26px"}
                 marginRight={"26px"} 
                marginBottom={"38px"}>
             <Button
              data-test-id="handleManageEmployeeSaveSettings" 
              onClick={this.handleManageEmployeeSaveSettings}
              disabled={this.state.selectedEmployees?.length === 0}
                  className="notificationButton" 
                  style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
                >Change to HR SubAdmin
                </Button> 
              </Grid>
          </Grid>
  </>} 
{ this.state.activeTab === "ManageCandidates" &&
          <>
            <Grid container 
            className="notificationGrid">
              <Search style={{
              flex: 1, border: "1px solid #94A3B8", display: "flex", flexDirection: "row-reverse",
                marginLeft: "26px",
                 marginRight: "26px"
              }}>
                <SearchIconWrapper> 
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase 
                placeholder="Search Employee" fullWidth inputProps={{ 'aria-label': 'search' }}
                /></Search>
              <TableContainer sx={{ marginLeft: "26px", marginRight: "26px", marginTop: "26px" }}>
                <Table> 
                  <TableHead><TableRow>
                    <TableCell className="tableDataText">
                      Employee Name
                    </TableCell>
                    <TableCell align="center" className="tableDataText">
                      Designation</TableCell>
                    <TableCell align="right" className="tableDataText">
                      Department</TableCell>
                    <TableCell align="center" sx={{ paddingLeft: "8rem" }}
                     className="tableDataText">
                      Action
                    </TableCell>
                    </TableRow></TableHead>
                    <TableBody>
                      {this.state.candidateRole.map((data:any) => (
                    <TableRow> 
                      <TableCell className="tableDataText">
                    <Checkbox 
                    data-test-id="checkCandidate"
                    checked={this.state.selectedCandidate.includes(data.id)}
                    onChange={() => this.handleCheckboxCandidateChange(data)}
                    sx={{ width: "20px", height: "20px", marginRight: "40px", }} />
                    {data.firstName + " " + data.lastName}
                      </TableCell>
                      <TableCell align="center" className="tableDataText">{data.designation}
                      </TableCell><TableCell
                        align="right"
                        className="tableDataText">
                        {data.department}
                        </TableCell>
                      <TableCell align="right">
                        <Button
                        data-test-id="rolesChangesCandidateButton"
                         disabled={!this.state.selectedCandidate.includes(data.id) || this.state.selectedCandidate?.length > 1}
                         onClick={() => this.handleChangeCandidateRole(data)}
                        sx={{
                          border: "1px solid #94A3B8",
                          padding: "10px 16px", textTransform: "none",
                          color: "#475569", fontFamily: "Urbanist",
                          fontSize: "14px", fontWeight: "700",
                          lineHeight: "22px",
                        }}>
                        Change to Employee</Button>
                      </TableCell>
                    </TableRow>
                      ))}
                  </TableBody> 
                  </Table>
              </TableContainer>
            </Grid> 
          {
            this.state.candidateRole?.length !== 0 &&
            <Grid container justifyContent="center" sx={{ mt: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Pagination count={Math.ceil(this.state.candidateRole?.length / 5)}
                  color="primary" siblingCount={0}
                  boundaryCount={1} /></Box>
            </Grid>
          }
            <Grid container>
              <Grid item xs={12}
                marginTop={"50px"} marginLeft={"26px"}
                marginRight={"26px"} marginBottom={"38px"}>
                <Button disabled={this.state.ModelShowEmployeeData.length === 0} data-test-id="handleManageCandidateSaveSettings" onClick={this.handleManageCandidateSaveSettings}
                  className="notificationButton"
                  style={{
                    width: "100%", paddingTop: "20px",
                    paddingBottom: "20px"
                  }}
                >{configJSON.saveChanges}
                </Button> </Grid>
            </Grid>
          </>
        }
  </Grid>
    </Grid>
  )
}

helpSupportSettingScreen = () => {
  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5}  className="notificationsettings" display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
    <Grid>
  <Grid container className="helpSupportGrid">
    <Grid item>
      <Box marginLeft={"24px"}>
        <Typography className="helpSupportTitle">
        Help and Support
        </Typography>
        <Typography className="notificationSubTitle" marginTop={"10px"}>
        Please fill out the form below to contact our support team. We’ll get back to you as soon as possible.
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} marginTop={"36px"}><Divider
      sx={{color: "#CBD5E1",marginLeft: '26px',
      marginRight: '26px',
      }} 
     />
  </Grid></Grid>
  <Grid 
  container 
  xs={12} 
  display="flex" 
  justifyContent="space-between" 
  flexWrap="wrap"
  gap={2}
  marginTop={"24px"}
>
  <Grid 
    item 
    xs={12} 
    sm={5.7}
    className="textfield1"
  >
    <Box marginLeft="24px">
      <Box className="textFields">
      <Typography className="languageTitle">First Name*</Typography>
      {this.state.touched.firstName && this.state.errors.firstName !== "" && (
        <Typography className="errorText">{this.state.errors.firstName}</Typography>
      )}
      </Box>
      <input
        className="textfieldStyle"
        data-test-id="first_name"
        required
        name="firstName"
        value={this.state.firstName}
        onChange={this.handleFirstNameChange}
        onBlur={this.handleFirstNameBlur}
      />
    </Box>
  </Grid>  

  <Grid 
    item 
    xs={12} 
    sm={5.7} 
    className="textfield"
  >
    <Box marginRight="24px">
    <Box className="textFields">
      <Typography className="languageTitle">Last Name</Typography>
      {this.state.touched.lastName && this.state.errors.lastName !== "" && (
        <Typography className="errorText">{this.state.errors.lastName}</Typography>
      )}
      </Box>
      <input
        className="textfieldStyle"
        data-test-id="lastName"
        required
        name="lastName"
        value={this.state.lastName}
        onChange={this.handleLastNameChange}
        onBlur={this.handleLastNameBlur}
      />
    </Box>
  </Grid>  
</Grid>
<Grid 
  container 
  xs={12} 
  marginTop="25px" 
  display="flex" 
  justifyContent="space-between" 
  flexWrap="wrap"
  gap={2}
>
  <Grid 
    item 
    xs={12} 
    sm={5.7}
    className="textfield1"
  >
    <Box marginLeft="24px">
    <Box className="textFields">
      <Typography className="languageTitle">Phone Number (Optional)</Typography>
      {this.state.touched.lastName && this.state.errors.phoneNumber !== "" && (
        <Typography className="errorText">{this.state.errors.phoneNumber}</Typography>
      )}
      </Box>
      <TextField
                    fullWidth
                    data-test-id="contact_number"
                    variant="outlined"
                    placeholder="(555) 000-0000"
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    sx={{
                        lineHeight: "20px",
                        '& .MuiOutlinedInput-root': {
                            "borderRadius": "8px",
                            '&:focus-visible': {
                                border: "none"
                            },
                            '&:hover': {
                                border: "none",
                                outline: "none"
                            },
                        },
                        "& .MuiSelect-icon": {
                            transform: 'none !important',
                        },
                        "& .MuiOutlinedInput-input": {
                            padding: "14px 16px"
                        },
                        "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
                            backgroundColor: "none",
                            border: "1px solid lightgray"
                        },
                        "& .MuiInputBase-root-MuiOutlinedInput-root": {
                            borderRadius: "8px"
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Select
                                    data-test-id="country_code"
                                    className="countryCodeText"
                                    value={this.state.selectedCountryCode}
                                    onChange={this.handleCountryCodeChange}
                                    variant="standard"
                                    IconComponent={(props) => <IconButton {...props} sx={{ padding: 0 }}><ExpandMoreIcon /></IconButton>}
                                    disableUnderline
                                    sx={{ minWidth: 70 }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 48 * 4,
                                                width: 'auto',
                                                overflowY: 'auto',
                                                marginLeft: "-10px"
                                            },
                                        },
                                    }}
                                >
                                    {this.state.countryCodeData.data?.map((code) => (
                                        <MenuItem key={code.country_name} value={`${code.country_code}`}>
                                            {code.country_code}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </InputAdornment>
                        ),
                    }}
                />
    </Box>
  </Grid>  

  <Grid 
    item 
    xs={12} 
    sm={5.7} 
    className="textfield"
  >
    <Box marginRight="24px">
    <Box className="textFields">
      <Typography className="languageTitle">Email Address</Typography>
      {this.state.touched.emailForHelp && this.state.errors.emailForHelp !== "" && (
        <Typography className="errorText">{this.state.errors.emailForHelp}</Typography>
      )}
      </Box>
      <input
        className="textfieldStyle"
        data-test-id="emailAddress"
        required
        name="emailAddress"
        value={this.state.emailForHelp}
        onChange={this.handleEmailChange}
        onBlur={this.handleEmailBlur}
      />
    </Box>
  </Grid>  
</Grid>
<Grid 
  container 
  xs={12} 
  marginTop="25px" 
  display="flex" 
  justifyContent="space-between" 
  flexWrap="wrap"
  gap={2}
>
  <Grid 
    item 
    xs={12} 
  >
    <Box marginLeft="24px" marginRight="24px">
    <Box className="textFields">
      <Typography className="languageTitle">What do you need help with?</Typography>
      {this.state.touched.helpRequest && this.state.errors.helpRequest !== "" && (
        <Typography className="errorText">{this.state.errors.helpRequest}</Typography>
      )}
      </Box>
      <textarea className="textfieldStyle1"
        data-test-id="helpRequest"
        required
        name="helpRequest"
        value={this.state.helpRequest}
        style={{resize:"none"}}
        onChange={this.handleHelpRequestChange}
        onBlur={this.handleHelpRequestBlur}/>
    </Box>
  </Grid>   
</Grid>
</Grid>
<Grid container>
  <Grid item xs={12} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"} className="savebutton">
    <Button onClick={this.handleHelpSupportChangeSuccess} data-test-id="helpSupportbutton"
      className="notificationButton"  style={{ width: "100%", paddingTop:"20px", paddingBottom:"20px"}} 
    >Submit
    </Button> </Grid></Grid>
    <Modal      
        open={this.state.helpSupportChangeSuccess}
        onClose={this.closeHelpSupportModal}>
        <CustomPopup 
        data-test-id="helpSupportSuccess"
        headingPopup={configJSON.helpSupportheadingPopup} 
        titlePopup={configJSON.successHelpSupportTitleChanged} 
        subTitlePopup = {configJSON.subHelpSupportTitleChangedPopup}
        buttonText={configJSON.buttonHelpSupportTextChanged}
        onOpen={this.openHelpSupportModal}
        onClose={this.closeHelpSupportModal}/>
      </Modal>
  </Grid>
  )
}

handleActionTab=()=>{
  return( <>
    <Grid 
    sx={{position:"absolute",
    top:"2.7rem",right:"7%"}}>
    <Grid 
    sx={{backgroundColor:"#FFFFFF",
    zIndex:9999,borderRadius:"0.8rem",
    padding:"0.3rem",
    boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}>
      <Box 
      sx={{backgroundColor:"#E2E8F0",
      borderRadius:"0.4rem",borderBottom:"1px solid #f1f5f9",width:"150px",height:"40px",
      display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Typography>
          Edit details
          </Typography></Box>
      <Box 
      sx={{height:"40px",display:"flex",
      width:"100%",alignItems:"center",
      justifyContent:"center"}}>
        <Typography sx={{color:"#F87171"}}>
          Delete details</Typography> </Box>
      </Grid> </Grid></>
  )}
handleDefineLevelActionTab=()=>{
  return(
     <>
    <Grid sx={{position:"absolute",
    top:"2.7rem",
    right:"7%"}}>
    <Grid  sx={{backgroundColor:"#FFFFFF",
    zIndex:9999,
    borderRadius:"0.8rem",
    padding:"0.3rem", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}>
      <Box sx={{backgroundColor:"#E2E8F0", borderRadius:"0.4rem",borderBottom:"1px solid #f1f5f9",width:"150px",height:"40px",
      display:"flex",
      alignItems:"center",justifyContent:"center"}}>
        <Typography> Edit details</Typography>
          </Box>
      <Box sx={{height:"40px",display:"flex",
      width:"100%",
      alignItems:"center",justifyContent:"center"}}>
        <Typography 
        sx={{color:"#F87171"}}>
          Delete details
          </Typography> 
          </Box>
      </Grid> 
      </Grid>
      </>
  )
}
handleDefineRatingPop=()=>{
  return(
    <>
    <Grid sx={{position:"absolute",top:"4.5rem",right:"50%"}}>
    <Grid sx={{backgroundColor:"#FFFFFF",zIndex:9999,borderRadius:"0.8rem",padding:"0.3rem",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}>
      <Box sx={{backgroundColor:"#E2E8F0",borderRadius:"0.4rem",borderBottom:"1px solid #f1f5f9",width:"150px",height:"40px",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Typography>Edit details</Typography>
      </Box>
      <Box sx={{height:"40px",display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
        <Typography sx={{color:"#F87171"}}>Delete details</Typography>
      </Box>
      </Grid>
    </Grid>
    </>
  )
}

talentProfileSettingScreen = () => {
  return (
 <>
     <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5} className="notificationsettings" sx={{backgroundColor:"#ffffff",height:"100%",pb:"30px"}} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Grid>
      <Grid container  justifyContent={"space-between"} className="" sx={{marginTop:"25px"}}>
      <Grid container sx={{display:"flex",alignItems:"center",gap:"1rem",flexWrap:"wrap",paddingX:"26px"}} className="headerTab">
      <Box
              data-test-id="DefineRating"
              onClick={() => this.handleTalentClick("DefineRating")}
              className={this.handleCondition(this.state.talentActiveTab === "DefineRating" ,  "defineRatingTabs" , "rolesHeading4")}
            >
              <Typography className={this.handleCondition(this.state.talentActiveTab === "DefineRating" , "rolesTitle" , "disableText")}>Define Rating Scale</Typography>
            </Box>
            <Box
            data-test-id="DefineJobfamily"
              onClick={() => this.handleTalentClick("DefineJobfamily")}
              className={this.handleCondition(this.state.talentActiveTab === "DefineJobfamily" ,  "defineRatingTabs" , "rolesHeading4")}
            >
              <Typography className={this.handleCondition(this.state.talentActiveTab === "DefineJobfamily" ,  "rolesTitle" , "disableText")}>
                Define Job Family</Typography>
            </Box>

            <Box
              data-test-id="DefineJobLevel"
              onClick={() => this.handleTalentClick("DefineJobLevel")}
              className={this.handleCondition(this.state.talentActiveTab === "DefineJobLevel" ,  "defineRatingTabs" , "rolesHeading4")}
            >
              <Typography className={this.handleCondition(this.state.talentActiveTab === "DefineJobLevel" , "rolesTitle" , "disableText")}
             >Define Job Level</Typography>
            </Box>
      </Grid> 
    </Grid>
{
  this.state.talentActiveTab === "DefineRating" && 
  <>
  <Grid item xs={12} marginTop={"25px"} sx={{backgroundColor:"#f1f5f9",marginX:"26px",borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
        <Box sx={{marginX:"26px",paddingY:"22px"}}>
      <Typography className="rolesTitle" >Define Rating Scale</Typography>
      <Typography className="subText">Please fill out the Generic rating scale.</Typography>
        </Box>
        <Divider
        sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px',
        }} 
       />
    </Grid>
   <Grid sx={{backgroundColor:"#f1f4f9",marginX:"26px"}}>
    <Box 
      sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingY:"20px",paddingX:"26px",gap:"1rem",flexWrap:"wrap"}}
    >
<Box
onClick={() => this.handleDefineTab("genRating")}
data-test-id="genRating"
className={this.handleCondition(this.state.defineTab === "genRating" , "defineRatingTabs" , "rolesHeading3")}
>
  <Typography className={this.handleCondition(this.state.defineTab === "genRating" , "rolesSubTitle" , "disableRolesSubTitle")}>Generic Rating Scale</Typography>
</Box>
<Box
data-test-id="comRating"

onClick={() => this.handleDefineTab("comRating")}
className={this.handleCondition(this.state.defineTab === "comRating" , "defineRatingTabs" , "rolesHeading3")}
>
  <Typography className={this.handleCondition(this.state.defineTab === "comRating" , "rolesSubTitle" , "disableRolesSubTitle")}>Compatancy Rating Scale</Typography>
</Box>
<Box
data-test-id="profRating"

onClick={() => this.handleDefineTab("profRating")}
className={this.handleCondition(this.state.defineTab === "profRating" , "defineRatingTabs" , "rolesHeading3")}
>
  <Typography className={this.handleCondition(this.state.defineTab === "profRating" , "rolesSubTitle" , "disableRolesSubTitle")}>Profeciency Rating Scale</Typography>
</Box>
<Box
data-test-id="perfoRating"
onClick={() => this.handleDefineTab("perfoRating")}
className={this.handleCondition(this.state.defineTab === "perfoRating" , "defineRatingTabs" , "rolesHeading3")}
>
  <Typography className={this.handleCondition(this.state.defineTab === "perfoRating" , "rolesSubTitle" , "disableRolesSubTitle")}>Performance Rating Scale</Typography>
</Box>
    </Box>
    <Divider
        sx={{color: "#CBD5E1",
        marginX: '26px',
        }} 
       />
   </Grid>
      </>
}
{this.state.talentActiveTab === "DefineRating" && (
  <>
  <TableContainer sx={{ marginX:"26px",pr:"38px",pl:"15px",backgroundColor:"#f1f4f9",width:"auto",overflow:"visible"}}>
  <Table sx={{marginX:"10px"}}>
      <TableHead>
          <TableRow>
              <TableCell className="tableTilteText">Level Name</TableCell>
              <TableCell className="tableTilteText">Level</TableCell>
              <TableCell className="tableTilteText">Description</TableCell>
              <TableCell className="tableDataText" sx={{textAlign:"center"}}>Action</TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
              <TableRow>
                  <TableCell className="tableTilteText"  sx={{width:"14rem"}}>{"Basic"}</TableCell>
                  <TableCell className="tableTilteText" sx={{width:"14rem"}}>{"2"}</TableCell>
                  <TableCell className="tableContentText" sx={{width:"12rem"}}>As a Network Engineer in our Network Operation Centre, you will be responsible for:</TableCell>
                  <TableCell sx={{textAlign:"center",position:"relative"}}>
                  <Grid item xs="auto" tabIndex={0} data-test-id="close_profile_dropdown" onBlur={this.closedefineRatPop}>
                    <MoreVertIcon
                    onClick={this.handleDefineRatingAction}
                    />
                    {this.state.defineRatingAction && (
                      this.handleDefineRatingPop()
                    )}
              </Grid>
                  </TableCell>
                 
              </TableRow>
              <TableRow >
                 
                  <TableCell className="tableTilteText" sx={{width:"14rem"}}>{"Basic"}</TableCell>
                  <TableCell className="tableTilteText"sx={{width:"14rem"}}>{"2"}</TableCell>
                  <TableCell className="tableContentText" sx={{width:"12rem"}}>As a Network Engineer in our Network Operation Centre, you will be responsible for:</TableCell>
                  <TableCell sx={{textAlign:"center"}}>
                    <MoreVertIcon />
                  </TableCell>
                  
              </TableRow>
      </TableBody>
  </Table>
</TableContainer>
<Divider sx={{ color: "#E2E8F0",backgroundColor:"#f1f5f9",mx:"50px" }} />
{!this.state.plusButton && (
<Grid sx={{backgroundColor:"#f1f5f9",mx:"26px",padding:"25px 0px 60px 25px",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
  <Box>
    <Button data-test-id="handleAddLevel" className="tableContentText" sx={{textTransform:"none",backgroundColor:"#E2E8F0",paddingX:"40px",display:"flex",gap:"0.5rem",alignItems:"center",color:"black",cursor:"pointer",paddingY:"15px",":hover":{backgroundColor:"#E2E8F0"}}}
    onClick={this.handleAddLevel}
    >
      <AddCircleOutlineIcon />
      Add Levels
    </Button>
  </Box>
</Grid>
)}
{this.state.plusButton && (<Grid  sx={{backgroundColor:"#f1f5f9",marginX:"26px",paddingX:"20px",paddingY:"20px",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
<Grid container spacing={2} sx={{marginTop:"5px"}}>
<Grid item lg={6} sm={12} sx={{width:"100%"}}>
<Typography className="disableRolesSubTitle">Level Name *</Typography>
<TextField
 sx={{
  marginTop:"10px",
  backgroundColor: "#ffffff",
  "& .MuiInputBase-input": {
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#0F172A",
  },
}}
     fullWidth
 />
</Grid>
<Grid item lg={6} sm={12} sx={{width:"100%"}}>
<Typography className="disableRolesSubTitle">Level *</Typography>

<FormControl fullWidth sx={{marginTop:"10px"}}>
  <Select
    fullWidth
    sx={{ backgroundColor: "#ffffff", width: "100%" }}
    className={`textContentWords`}
    data-test-id="rating"
    renderValue={(value: string) => {
      return value
    }}
  >
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
</FormControl>
</Grid>
</Grid>
<Typography className="disableRolesSubTitle" sx={{marginTop:"15px"}}>Description *</Typography>
<textarea
  className={`cultureInputField`}
  placeholder="Write description..."
  name="jobSummary"
  style={{marginTop:"10px"}}
/>
    <Box sx={{width:"100%"}}>
      <Button sx={{textTransform:"none",fontSize:"16px",fontWeight:700,backgroundColor:"#044352"
      ,color:"#ffffff",width:"100%",py:"1rem",mt:"1rem",":hover":{backgroundColor:"#044352"}}} >
        Save Generic Rating Scale</Button>
    </Box>
</Grid>)}
  </>
)}
{this.state.talentActiveTab === "DefineJobfamily" && (
  <>
  <Grid item xs={12} marginTop={"25px"} sx={{backgroundColor:"#f1f5f9",marginX:"26px",borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
        <Box sx={{marginX:"26px",paddingY:"22px"}}>
      <Typography className="rolesTitle" >Define Job Family</Typography>
      <Typography className="subText">Please fill out the Job Family details.</Typography>
        </Box>
        <Divider
        sx={{color: "#CBD5E1",marginLeft: '26px',
        marginRight: '26px',
        }} 
       />
    </Grid>
  <TableContainer sx={{ marginX:"26px",pr:"38px",pl:"15px",backgroundColor:"#f1f4f9",width:"auto",overflow:"visible"}}>
  <Table sx={{marginX:"10px",overflowY:"visible"}}>
      <TableHead>
          <TableRow >
              <TableCell className="tableTilteText">Job Family</TableCell>
              <TableCell className="tableTilteText">Description</TableCell>
              <TableCell className="tableDataText" sx={{textAlign:"end"}}>Action</TableCell>
            
          </TableRow>
      </TableHead>
      <TableBody sx={{position:"relative"}}>
              <TableRow sx={{overflowY:"visible"}}>
                
                  <TableCell className="tableTilteText"  sx={{width:"14rem"}}>{"Basic"}</TableCell>
                  <TableCell className="tableContentText" sx={{width:"12rem"}}>Job Family description</TableCell>
                  <TableCell sx={{textAlign:"end",position:"relative"}}
                  >
              <Grid item xs="auto" tabIndex={0} data-test-id="closeListIconPopup" onBlur={this.closeListIconPopup}>
                    <MoreVertIcon
                    onClick={this.handleAction}
                    />
                    {this.state.jobFamilyAction && (
                      this.handleActionTab()
                    )}
              </Grid>
                  </TableCell>
                 
              </TableRow>
              <TableRow >
                 
                  <TableCell className="tableTilteText" sx={{width:"14rem"}}>{"Basic"}</TableCell>
                  <TableCell className="tableContentText" sx={{width:"12rem"}}>Job Family description</TableCell>
                  <TableCell sx={{textAlign:"end"}}>
                    <MoreVertIcon />
                  </TableCell>
                  
              </TableRow>
      </TableBody>
  </Table>
</TableContainer>
<Divider sx={{ color: "#E2E8F0",backgroundColor:"#f1f5f9",mx:"50px" }} />
{!this.state.jobFamilyPlusButton && (
<Grid sx={{backgroundColor:"#f1f5f9",minHeight:"36vh",mx:"26px",padding:"25px 0px 60px 25px",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
  <Box>
    <Button data-test-id="handleAddFamily" className="tableContentText" sx={{textTransform:"none",backgroundColor:"#E2E8F0",paddingX:"40px",display:"flex",gap:"0.5rem",alignItems:"center",color:"black",cursor:"pointer",paddingY:"15px",":hover":{backgroundColor:"#E2E8F0"}}}
    onClick={this.handleAddJobFamily}
    >
      <AddCircleOutlineIcon />
      Add Job Family
    </Button>
  </Box>
</Grid>
)}
{this.state.jobFamilyPlusButton && (<Grid  sx={{backgroundColor:"#f1f5f9",marginX:"26px",paddingX:"20px",paddingY:"20px",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
<Grid sx={{marginTop:"5px"}}>
<Grid sx={{width:"100%"}}>
<Typography className="disableRolesSubTitle">Job Family *</Typography>
<TextField
 sx={{
  marginTop:"10px",
  backgroundColor: "#ffffff",
  "& .MuiInputBase-input": {
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#0F172A",
    width:"100%"
  },
}}
     fullWidth
 />
</Grid>
</Grid>
<Typography className="disableRolesSubTitle" sx={{marginTop:"15px"}}>Description *</Typography>
<textarea
  className={`cultureInputField`}
  placeholder="Write description..."
  name="ratingScale"
  style={{marginTop:"10px"}}
/>
    <Box sx={{width:"100%"}}>
      <Button sx={{textTransform:"none",fontSize:"16px",fontWeight:700,backgroundColor:"#044352"
      ,color:"#ffffff",width:"100%",py:"1rem",mt:"1rem",":hover":{backgroundColor:"#044352"}}} >
        Save Job Family</Button>
    </Box>
</Grid>)}
  </>
)}
{this.state.talentActiveTab === "DefineJobLevel" && (
      <>
      <Grid item xs={12} marginTop={"25px"} sx={{backgroundColor:"#f1f5f9",marginX:"26px",borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
            <Box sx={{marginX:"26px",paddingY:"22px"}}>
          <Typography className="rolesTitle" >Define Job Level</Typography>
          <Typography className="subText">Please fill out the Job Level details.</Typography>
            </Box>
            <Divider
            sx={{color: "#CBD5E1",marginLeft: '26px',
            marginRight: '26px',
            }} 
           />
        </Grid>
      <TableContainer sx={{ marginX:"26px",pr:"38px",pl:"15px",backgroundColor:"#f1f4f9",width:"auto",overflow:"visible"}}>
      <Table sx={{marginX:"10px",overflowY:"visible"}}>
          <TableHead>
              <TableRow >
                  <TableCell className="tableTilteText">Job Level</TableCell>
                  <TableCell className="tableTilteText">Description</TableCell>
                  <TableCell className="tableDataText" sx={{textAlign:"end"}}>Action</TableCell>
                
              </TableRow>
          </TableHead>
          <TableBody sx={{position:"relative"}}>
                  <TableRow sx={{overflowY:"visible"}}>
                    
                      <TableCell className="tableTilteText"  sx={{width:"14rem"}}>1</TableCell>
                      <TableCell className="tableContentText" sx={{width:"12rem"}}>Job Level description</TableCell>
                      <TableCell sx={{textAlign:"end",position:"relative"}}
                      >
                  <Grid item xs="auto" tabIndex={0} data-test-id="closeLevelPop" onBlur={this.closeLevelPop}>
                        <MoreVertIcon
                        onClick={this.handleDefineLevel}
                        />
                        {this.state.jobLevelAction && (
                          this.handleDefineLevelActionTab()
                        )}
                  </Grid>
                      </TableCell>
                     
                  </TableRow>
                  <TableRow >
                     
                      <TableCell className="tableTilteText" sx={{width:"14rem"}}>2</TableCell>
                      <TableCell className="tableContentText" sx={{width:"12rem"}}>Job Level description</TableCell>
                      <TableCell sx={{textAlign:"end"}}>
                        <MoreVertIcon />
                      </TableCell>
                      
                  </TableRow>
                  <TableRow >
                     
                     <TableCell className="tableTilteText" sx={{width:"14rem"}}>3</TableCell>
                     <TableCell className="tableContentText" sx={{width:"12rem"}}>Job Level description</TableCell>
                     <TableCell sx={{textAlign:"end"}}>
                       <MoreVertIcon />
                     </TableCell>
                     
                 </TableRow>
          </TableBody>
      </Table>
    </TableContainer>
    <Divider sx={{ color: "#E2E8F0",backgroundColor:"#f1f5f9",mx:"50px" }} />
    {!this.state.jobLevelPlusButton && (
    <Grid sx={{backgroundColor:"#f1f5f9",minHeight:"36vh",mx:"26px",padding:"25px 0px 60px 25px",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
      <Box>
        <Button data-test-id="handleAddFamily" className="tableContentText" sx={{textTransform:"none",backgroundColor:"#E2E8F0",paddingX:"40px",display:"flex",gap:"0.5rem",alignItems:"center",color:"black",cursor:"pointer",paddingY:"15px",":hover":{backgroundColor:"#E2E8F0"}}}
        onClick={this.handleAddJobLevel}
        >
          <AddCircleOutlineIcon />
          Add Job Level
        </Button>
      </Box>
    </Grid>
    )}
    {this.state.jobLevelPlusButton && (<Grid  sx={{backgroundColor:"#f1f5f9",marginX:"26px",paddingX:"20px",paddingY:"20px",borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
    <Grid sx={{marginTop:"5px"}}>
    <Grid sx={{width:"100%"}}>
    <Typography 
    className="disableRolesSubTitle">
      Job Level *
      </Typography>
    <FormControl fullWidth 
    sx={{marginTop:"10px"}}>
  <Select fullWidth sx={{ backgroundColor: "#ffffff",
   width: "100%"}} className={`textContentWords`}
   data-test-id="jobLevel"
    renderValue={(
      value: string) => { return value }}>
    <MenuItem 
    value={10}>
      Ten</MenuItem>
    <MenuItem 
    value={20}>
      Twenty
      </MenuItem>
    <MenuItem 
    value={30}>
      Thirty
      </MenuItem> </Select>
  </FormControl></Grid>
    </Grid> <Typography className="disableRolesSubTitle"
     sx={{marginTop:"15px"}}>
      Description *
      </Typography>
    <textarea className={`cultureInputField`}
      placeholder="Write description..." name="joblevel"
      style={{marginTop:"10px"}} />
        <Box 
        sx={{width:"100%"}}>
          <Button 
          sx={{textTransform:"none",fontSize:"16px",fontWeight:700,backgroundColor:"#044352",color:"#ffffff",
          width:"100%",py:"1rem",mt:"1rem",":hover":{backgroundColor:"#044352"}}} >
            Save Job Level
            </Button>
      </Box></Grid>
    )}
      </>
)}
  </Grid>
    </Grid>
 </>
  )
}

successUserCreationDialog = () => {
  return (
    <Dialog
      open={this.state.openSuccessComp}
      onClose={this.handleCloseSuccessComp}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 32px 8px",
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle>
        <DilogWrapper>
          <Typography className="successCompDialogTitle">
          Role Conversion to HR SubAdmin
          </Typography>
          <IconButton
            aria-label="close"
            data-test-id="close_competency_success"
            onClick={this.handleCloseSuccessComp}
            sx={{ right:  30, left: "auto"}}
            className="successCompDialogClose"
          >
            <CloseIcon />
          </IconButton>
        </DilogWrapper>
      </DialogTitle>
      <Divider sx={{ color: "#E2E8F0" }} />
      <DialogContent>
        <DilogWrapper>
          <Box className="successCompImageBox">
            <img alt="Success Image" src={right.default} height={98} width={98} />
          </Box>
        </DilogWrapper>
        <Box style={{borderRadius:"12px" , backgroundColor:"#F1F5F9"}}>
          {(this.state.selectedEmployees.length === 1) 
          &&  <Typography
          sx={{ fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center" }}>
          {`${this.state.employeeDataPopup.firstName} ${this.state.employeeDataPopup.lastName}`}</Typography> }
        <Typography sx={{fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>Employee
        </Typography>
        <DilogWrapper>
          <Box className="successCompImageBox1">
            <img src={chnageRole.default} alt="Success Image" height={15.86} width={20.52} />
          </Box>
        </DilogWrapper>
        {(this.state.selectedEmployees.length === 1) && 
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          {`${this.state.employeeDataPopup.firstName} ${this.state.employeeDataPopup.lastName}`}
        </Typography>
        }
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          HR SubAdmin
        </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Urbanist",
            padding: "0px 40px",
            marginTop:"22px",
            fontSize: "16px",
            color: "#0F172A",
            textAlign: "center",
          }}>
          The user will receive an email notification about this change.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Urbanist",
            padding: "0px 68px",
            fontSize: "16px",
            color: "#0F172A",
            textAlign: "center",
          }}>
          This user will lose access to certain features.
        </Typography>
      </DialogContent>
      <DialogActions>
        <DilogWrapper>
          <Box className={`compButtonsBox`}>
          <Button
              color="primary"
              variant="contained"
              onClick={this.handleCloseSuccessComp}
              className={`viewCompButton1`}
            >
              Cancel
            </Button>
            <Button
            data-test-id="handleOpenSuccessComp"
              color="primary"
              variant="contained"
              onClick={this.handleOpenSuccessComp}
              className={`viewCompButton`}
            >
              Proceed
            </Button>
          </Box>
        </DilogWrapper>
      </DialogActions>
    </Dialog>
  )
}

SuccessSettingDialogChange = () =>{
  return(
    <Dialog
      open={this.state.HrSubAdminSuccessModelState}
      onClose={this.HrSubAdminSuccessModelStateClose}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 32px 8px",
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle>
        <DilogWrapper>
          <Typography className="successCompDialogTitle">
          HR SubAdmin Settings saved
          </Typography>
          <IconButton
            aria-label="close"
            data-test-id="close_competency_success"
            sx={{
              right:  30,
              left: "auto"
            }}
            onClick={this.HrSubAdminSuccessModelStateClose}
            className="successCompDialogClose"
          >
            <CloseIcon />
          </IconButton>
        </DilogWrapper>
      </DialogTitle>
      <Divider sx={{
        color: "#E2E8F0"
      }} />
      <DialogContent>
        <DilogWrapper>
          <Box className="successCompImageBox">
            <img src={right.default}
              alt="Success Image"
              height={98}
              width={98} />
          </Box>
        </DilogWrapper>
        <Box style={{borderRadius:"12px" , backgroundColor:"#F1F5F9"}}>
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Urbanist",
            padding: "0px 40px",
            marginTop:"22px",
            fontSize: "16px",
            color: "#0F172A",
            textAlign: "center",
          }}>
            HR SubAdmin settings have been updated successfully.
        </Typography>
      </DialogContent>
      <DialogActions>
        <DilogWrapper>
          <Box className={`compButtonsBox`}>
            <Button
            data-test-id="handleOpenSuccessComp"
              color="primary"
              variant="contained"
              onClick={this.HrSubAdminSuccessModelStateClose}
              className={`viewCompButton`}
            >
              Done
            </Button>
          </Box>
        </DilogWrapper>
      </DialogActions>
    </Dialog>
  )
} 

successUserCandidateDialog = () => {
  return (
    <Dialog
      open={this.state.openSuccessCandidateComp}
      onClose={this.handleCloseCandidateSuccessComp}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 32px 8px", overflow: 'hidden',
        }}}>
      <DialogTitle>
        <DilogWrapper>
          <Typography className="successCompDialogTitle"> Role Conversion to Employee </Typography>
          <IconButton
            aria-label="close" data-test-id="handleCloseSuccessComp"
            onClick={this.handleCloseCandidateSuccessComp} className="successCompDialogClose"
            sx={{ right:  30,left: "auto"}}
          > <CloseIcon /></IconButton>
        </DilogWrapper></DialogTitle>
      <Divider sx={{
         color: "#E2E8F0"
       }} />
      <DialogContent>
        <DilogWrapper>
          <Box className="successCompImageBox"><img src={right.default} alt="Success Image" height={98} width={98} /></Box>
        </DilogWrapper>
        <Box style={{
          borderRadius:"12px" ,
           backgroundColor:"#F1F5F9"}}>
          {this.state.selectedCandidate.length === 1 && 
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          {`${this.state.ModelShowEmployeeData[0].firstName} ${this.state.ModelShowEmployeeData[0].lastName}`}
        </Typography>
          }
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          {this.state.candidateDataPopup.designation}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",
            padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          Candidate
        </Typography>
        <DilogWrapper>
          <Box className="successCompImageBox1">
            <img src={chnageRole.default} alt="Success Image" height={15.86} width={20.52} />
          </Box>
        </DilogWrapper>
        {this.state.selectedCandidate.length === 1 && 
        <Typography
        sx={{
          fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
        }}>
          {`${this.state.ModelShowEmployeeData[0].firstName} ${this.state.ModelShowEmployeeData[0].lastName}`}
        </Typography>
        }
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",
            padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          Employee
        </Typography>
        </Box>
        <Typography
          sx={{fontWeight: 400,lineHeight: "24px",fontFamily: "Urbanist",padding: "0px 40px",marginTop:"22px",fontSize: "16px",
            color: "#0F172A",textAlign: "center",
          }}>
          The user will receive an email notification about this change.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,lineHeight: "24px",fontFamily: "Urbanist",padding: "0px 68px",fontSize: "16px",color: "#0F172A",textAlign: "center",
          }}>
          This user will lose access to certain features.
        </Typography>
      </DialogContent>
      <DialogActions>
        <DilogWrapper>
          <Box className={`compButtonsBox`}>
          <Button
          data-test-id="handleCloseCandidateSuccessComp" color="primary" variant="contained" className={`viewCompButton1`}
              onClick={this.handleCloseCandidateSuccessComp} 
            >
              Cancel
            </Button>
            <Button data-test-id="handleOpenCandidateSuccessComp"
              color="primary" variant="contained"
              onClick={this.handleOpenCandidateSuccessComp} className={`viewCompButton`}
            >
              Proceed
            </Button>
          </Box>
        </DilogWrapper>
      </DialogActions>
    </Dialog>
  )
}
  // Customizable Area End

  render() {
    console.log("language")
    return (
      // Customizable Area Start
      <>
      <View style={{ display: "none", alignItems: "center" }}>
        <View style={styles.mainWrapper}>
          <Typography >{configJSON.labelTitleText}</Typography>
          <Typography >{configJSON.labelBodyText}</Typography>
          <View >
            <Input
              data-test-id="txtInput"
              placeholder={configJSON.txtInputPlaceholder}
              label={`This is the received value: ${this.state.txtSavedValue}`}
              {...this.txtInputProps}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  {...this.btnShowHideProps}
                  style={{ backgroundColor: "" }}
                >
                  {this.state.enableField ? (
                    <img src={imgVisbility} />
                  ) : (
                    <img src={imgVisbilityOff} />
                  )}
                </Button>
              }
            />
          </View>
          <View style={styles.buttonStyle}>
            <Button
              data-test-id="btnAddExample"
              onClick={() => this.doButtonPressed()}
              >
              CLICK ME
              </Button>
          </View>
        </View>
      </View>
      <DashboardHeader navigation={this.props.navigation} id={this.props.id}>
            <Wrapper>
                <Grid container display={"flex"} justifyContent={"center"}>
                  <HeaderWrapper>
                    <Grid item xs={11}>
                      <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                          <Box data-test-id="Setting" onClick={this.navigateToDashboard}
                            style={{ width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}
                          >
                            <img src={arrow_Left.default}
                              alt="Back Arrow"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} alignItems={"center"}>
                          <Typography className="settingTitle">Settings</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </HeaderWrapper>
                  </Grid>
                  <MainWrapper>
                  <Grid
                      item
                      xs={9}
                      sm={7}
                      md={4}
                      lg={4}
                      xl={3.6}
                      sx={{
                        maxWidth: {
                          xs: "85%",
                          sm: "66%",
                          md: "40%",
                          lg: "40%", 
                          xl: "35%",
                        },
                        
                      }}
                    >
                      <Card className="companyCardDescBox" style={{boxShadow: "0px 14px 144px 0px #0000001A",marginBottom:"20px"}}>
                        <CardContent>
                          <Grid item>
                          <Grid item xs={11}
                          onClick={this.profileScreen}
                          marginRight={"30px"}
                          marginLeft={"30px"}
                          marginTop={"10px"}
                            data-test-id="navigate_to_ProfileSetting"
                            className={`menu ${this.state.profileSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.profileTab()}
                          </Grid>
                          <Grid item xs={11}
                            onClick={this.notificationScreen}
                            marginRight={"30px"}
                            marginLeft={"30px"}
                            marginTop={"10px"}
                            data-test-id="navigate_to_NotificationSetting"
                            className={`menu ${this.state.notificationSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.notificationTab()}
                          </Grid>
                          <Grid item xs={11} data-test-id="navigate_to_LangaugeSetting"
                          onClick={this.languageScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                            className={`menu ${this.state.languageSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.languageTab()}
                          </Grid>
                          <Grid item xs={11}
                          onClick={this.rolesPermissionScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                           data-test-id="navigate_to_RolesPermissionSetting"
                            className={`menu ${this.state.rolespermissionSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.rolesPermissionTab()}
                          </Grid>
                          <Grid item xs={11}
                           onClick={this.talentProfileScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                           data-test-id="navigate_to_TalentProfileSettings"
                            className={`menu ${this.state.talentProfileSettingScreen === true ? 'activeMenuTitle' : ''}`}
                            >
                            {this.talentProfileTab()}
                          </Grid>
                          <Grid item xs={11}
                          onClick={this.helpSupportScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                            data-test-id="navigate_to_HelpSettings"
                            className={`menu ${this.state.helpSupportSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.helpTab()}
                          </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    {this.state.profileSettingScreen && this.profileSettingScreen()}
                    {this.state.notificationSettingScreen && this.notificationSettingScreen()}
                    {this.state.languageSettingScreen && this.languageSettingScreen()}
                    {this.state.rolespermissionSettingScreen && this.rolespermissionSettingScreen()}
                    {this.state.helpSupportSettingScreen && this.helpSupportSettingScreen()}
                    {this.state.openSuccessComp && this.successUserCreationDialog()}
                    {this.state.openSuccessCandidateComp && this.successUserCandidateDialog()}
                    {this.state.talentProfileSettingScreen && this.talentProfileSettingScreen()}
                  </MainWrapper>
            </Wrapper>
            </DashboardHeader>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const AntSwitch = styled(Switch)({
  width: 48,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(25px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "#375280",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#375280",
      border: "none",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    color:"#FFFFFF",
    margin: 2
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E2E8F0",
    opacity: 1,
    borderRadius: 40,
    height: 24,
    width: 48
  },
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#044352',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#044352',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundColor: '#94A3B8'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E2E8F0',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#E2E8F0',
    }),
  },
}));

export const HeaderWrapper = styled(Box)({
  display: "flex",
  alignItems:"center",
  justifyContent: "center",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  "& .settingTitle": {
    fontFamily: "Urbanist",
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#000104",
    marginLeft: "12px"
},
})

export const DilogWrapper = styled(Box)({
  width: "100%",
  "& .successCompDialogClose": {
    top: 16,
    position: 'absolute',
    color: "#334155",
    "@media(max-width:600px)": {
      marginRight:"-25px"
  },
  },
  "& .successCompImageBox": {
    display: "flex",
    padding: "10px 0px",
    justifyContent: "center",
    marginBottom:"22px"
  },
  "& .successCompImageBox1": {
    display: "flex",
    padding: "10px 0px",
    justifyContent: "center",
  },
  "& .successCompDialogTitle": {
    fontFamily: "Urbanist",
    color: "#1E1E1E",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    margin: "8px 16px"
  },
  "& .compButtonsBox": {
    margin: "16px 0px",
    paddingRight: "16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .viewCompButton1":{
    padding: "16px 24px",
    fontWeight: 700,
    width: "30%",
    height: "56px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    marginLeft: "16px",
    background: "#F1F5F9",
    textTransform: "none",
    color:"#044352",
    "&:hover": {
      backgroundColor: "#F1F5F9"
    },
  },
  "& .viewCompButton": {
    padding: "16px 24px",
    fontWeight: 700,
    width: "30%",
    height: "56px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    marginLeft: "16px",
    background: "#044352",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#044352"
    },
  },
 
})

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '28px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: "10px 16px",
  color: 'inherit',
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const Wrapper = styled(Box)({
  borderRight: "1px solid rgba(236, 236, 236, 1)",
  height: "100%",
  minHeight: "100vh",
  "& .logoWrapper": {
      height: "150px"
  },
  "& .menu": {
      padding: "20px 10px 25px 20px",
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      alignItems: "start",
  },
  "& .menuProfileTitle": {
    paddingLeft: "15px",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "26px",
    color: "#044352",
},
"& .subText":{
  fontStyle:"Urbanist",
  fontWeight:"400",
  fontSize:"14px",
  lineHeight:"22px",
  color:"#334155"
},
  "& .menuTitle": {
      paddingLeft: "15px",
      fontFamily: "Urbanist",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "26px",
      color: "#0F172A",
  },
  "& .menuSubTitle": {
    paddingLeft: "15px",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#334155",
},
  "& .activeMenuTitle": {
      backgroundColor : "#F1F5F9"
  },
  "& .profilesettings": {
    marginLeft:"30px",
    boxShadow: "0px 14px 144px 0px #0000001A"
  },
  "& .notificationsettings": {
    marginLeft:"30px",
    boxShadow: "0px 14px 144px 0px #0000001A"
  },
  "& .cultureInputField": {
    boxShadow: "0px 1px 2px 0px #1018280D",
    fontSie: "16px",
    fontFamily: 'Urbanist',
    border: '1px solid #CBD5E1',
    fontWeight: 400,
    lineHight: "24px",
    borderRadius: "8px",
    height: "98px",
    padding: "12px 16px",
    width: '100%',
    color: "#475569",
    "&:focus": {
        borderColor: "#CBD5E1",
        outline: "none",
    }
},
  "& .dropDownStyle": {
    backgroundColor: 'white',
    height: '66px',
    marginTop:"10px",
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    fontSie: '14px',
    fontWeight: 400,
    lineHight: "22px",
    fontFamily: 'Urbanist',
    padding: '10px 12px',
    width: '100%',
    color: "#334155"
},
"& .savebutton":{
  "@media(max-width:1050px)": {
    marginTop:"150px"
}
},
})
export const MainWrapper = styled(Box)({
  display: "flex",
  justifyContent: "start",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  flexWrap:"wrap",
  "& .jobMainHeaderGrid": {
    marginTop: "30px"
},
"& .notificationGrid": {
  marginTop: "50px"
},
"& .helpSupportGrid": {
  marginTop: "30px"
},
"& .tableDataText": {
  color:"#475569",
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "22px",
  paddingTop: "30px",
  paddingBottom: "30px"
},
"& .tableDataText1": {
  paddingTop: "30px",
  paddingBottom: "30px",
},
"& .notificationButton":{
  backgroundColor:"#044352",
  color:"#FFFFFF",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  textTransform: "none"
},
"& .profileImageMain": {
  display: "flex",
  justifyContent: "center",
},
"& .profileImage": {
  width: "10vw",
  height: "10vw",
  maxWidth: "150px",
  maxHeight: "150px",
  minWidth: "50px",
  minHeight: "50px",
  borderRadius: "50%",
  border: "4px solid rgba(241, 245, 249, 1)",
  backgroundColor: "rgb(226, 232, 240)",
  opacity: "0px",
  zIndex: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
},
"& .profileText": {
  marginLeft:"26px",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#64748B",
  "@media(max-width:1050px)": {
    marginLeft:"26px"
},
},
"& .notificationTitle": {
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#044352",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
},
},
"& .helpSupportTitle": {
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "26px",
  color:"#0F172A",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
},
},
"& .languageTitle": {
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "22px",
  color:"#64748B",
  marginBottom:"10px",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
}
},
"& .countryCodeText": {
  paddingTop: "3px",
  textAlign: "center",
  paddingRight: "12px"
},
"& .textFields":{
  display: "flex",
  justifyContent: "space-between"
},
"& .errorText":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color:"#F87171",
},
"& .textfield":{
  "@media(max-width:600px)": {
    marginLeft:"24px",
}
},
"& .textfield1":{
  "@media(max-width:600px)": {
    marginRight:"24px",
}
},
"& .rolesHeadingBox":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 60px" , 
  borderRadius:"4px",
  marginLeft:"56px",
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .talenrHeadingBox":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 45px" , 
  borderRadius:"4px",
  color:"#044352",
  fontWeight: 700,
  // marginLeft:"56px",
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .defineRatingTabs":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"10px 20px" , 
  borderRadius:"4px",
  color:"#044352",
  fontWeight: 700,
    // marginLeft:"56px",
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .rolesHeading1":{
  "@media(max-width:1285px)": {
    marginLeft:"56px",
    marginRight:"56px",
},
},
"& .rolesHeadingBox1":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 60px" , 
  borderRadius:"4px",
  "@media(max-width:1285px)": {
    marginLeft:"56px",
    marginRight:"56px",
},
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .rolesHeading2":{
  "@media(max-width:1285px)": {
    marginRight:"56px",
    marginLeft:"56px"
},
},
"& .rolesHeadingBox2":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 60px" , 
  borderRadius:"4px",
  // marginRight:"24px",
  "@media(max-width:1285px)": {
    marginRight:"56px",
    marginLeft:"56px"
},
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .rolesHeading3":{
marginLeft:"20px",
},
"& .rolesTitle":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#044352",
  cursor:"pointer",
  "@media(max-width:1285px)": {
    marginLeft:"0px"
},
},
"& .tableTilteText":{
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "22px",
  color:"#475569",
},
"& .tableContentText":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color:"#0F172A",
},
"& .disableText":{
  fontFamily: "Urbanist",
  fontSize: "17.64px",
  fontWeight: "700",
  lineHeight: "26.46px",
  color:"#64748B",
  cursor:"pointer",
},
"& .headerTab":{
  "@media(max-width:700px)": {
    justifyContent:"center",
    backgroudColor:"blue"
  },
  "@media(min-width:700px)": {
    justifyContent:"space-between",
    backgroudColor:"black"
},
},
"& .rolesSubTitle":{
  fontFamily: "Urbanist",
  fontSize: "15px",
  fontWeight: "700",
  color:"#044352",
  cursor:"pointer",
},
"& .disableRolesSubTitle":{
  fontFamily: "Urbanist",
  fontSize: "15px",
  fontWeight: "700",
  color:"#64748B",
  cursor:"pointer",
},
"& .textContentWords":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#0F172A",
},
"& .notificationSubTitle": {
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
  color:"#334155",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
},
},
"& .switchButton": {
  marginLeft : "0px",
  "@media(max-width:1280px)": {
    marginLeft:"26px"
},
},
"& .formText": {
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#64748B",
  marginBottom:"10px"
},
"& .formField":{
  "@media(max-width:1050px)": {
    marginLeft:"26px"
},
},
"& .profileButton": {
  fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    color:"#044352",
    textTransform: "none"
},
"& .buttonsprofile":{
  "@media(max-width:1050px)": {
    marginLeft:"20px"
},
},
"& .deleteButton": {
  fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    color:"#DC2626",
    textTransform: "none"
},
"& .textfieldStyle": {
  backgroundColor: 'white',
  height: '60px',
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  fontSie: '16px',
  fontWeight: 700,
  lineHight: "24px",
  fontFamily: 'Urbanist',
  padding: '10px 12px',
  width: '100%',
  color: "#475569"
},
"& .textfieldStyle1": {
  backgroundColor: 'white',
  height: '135px',
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  fontSie: '16px',
  fontWeight: 700,
  lineHight: "24px",
  fontFamily: 'Urbanist',
  padding: '10px 12px',
  width: '100%',
  color: "#475569"
},
});

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
