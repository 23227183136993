import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField, IconButton, InputAdornment, Grid,InputLabel
} from "@mui/material";
import {
  createTheme,
  styled,
  ThemeProvider,

} from "@mui/material/styles";
import { resetPasswordImage, signInImage, signInLogo } from "./assets";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StyleSheet } from "react-native";
import { underReviewImage } from "../../../blocks/languagesupport/src/assets";
import { withTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiButton: {
        styleOverrides: {
            root: {
                backgroundColor: 'inherit',
                '&:hover': {
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                },
            },
        },
    },
},
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const elemStyle = {


  loginDes: {
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#475569',
    margin: '12px 0',

  },

  loginBorder: {
    width: '100%',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
    }
  },

  loginLabel: {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#334155',
    letterSpacing: '0.08px',
  },
  notAccount: {
    fontFamily: 'Urbanist',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '26px',
  color: '#0F172A',
  letterSpacing: '0.12px',

  },
  logStyles: {
    cursor: 'pointer',
    fontFamily: 'Urbanist',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '26px',
  color: '#044352',
  letterSpacing: '0.12px', 
  textTransform: 'none', 

  },

 signInBtnTitle: {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#FFFFFF',
    letterSpacing: '0.12px', 
    textTransform: "capitalize"
  },
  tips: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "left",
    gap: "0.5rem",
    marginTop: "1rem"
  },
  forgtPass: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.5rem",
    marginTop: "1rem"
  },
  forgotPassWordTitle:{
    cursor:"pointer",
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#0F172A',
    letterSpacing: '0.12px',
    textTransform: "none"
  },
  signInTitle: {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '44px',
    letterSpacing: '-0.5%',
    color: '#FFFFFF',
  },

  signInSDescr: {
    
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    margin: '12px 0',
    color: '#ffffff',
    width: "100%"
  },



  signInstyle: {
    fontFamily: 'Urbanist',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    letterSpacing: '-0.5%',
    color: '#000000',
  },

  textFeildLabel:{
    fontFamily: 'Urbanist',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#94A3B8',
    letterSpacing: '0.08px',
  },

  textFeildProps:{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      borderRadius: '8px',
    },
      '& .MuiInputBase-input': {
        color: '#0F172A',
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.08px',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#94A3B8',
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.08px',
      },
      '& .MuiInputBase-input:hover': {
        borderColor: '#1976d2',
      },
      '& .MuiFormHelperText-root': {
        marginLeft: 0,
        marginTop: '4px',
      }
  }


};
import { configJSON } from "./EmailAccountLoginController";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
resetScreen(){
  return(
    <>
      <Box component="form" sx={{
        '& > :not(style)': { width: '100%', marginTop: "0.25rem" },
      }}
        noValidate
        autoComplete="off"
      >
        <InputLabel sx={elemStyle.loginLabel} htmlFor="my-input">
        {this.props.i18n?.t("register.email")}
        </InputLabel>
        <TextField
          required
          data-test-id="Email"
          name="email"
          value={this.state.loginDetails.email}
          placeholder={this.props.i18n?.t("register.yourEmail")}
          sx={
            elemStyle.textFeildProps
          }
          FormHelperTextProps={{
            sx: {
              fontFamily: 'Urbanist',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '18px',
              color: '#DC2626',
              marginLeft: 0,
              marginTop: '8px',
              textAlign: this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "left" , "right") ,
                    direction: this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "ltr" , "rtl") 
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              this.handleButtonClick(event);
            }
          }}
          onChange={this.handleChangeLogin}
          error={!!this.state.loginErrors.email || !! this.state.wrongCredentialError?.errors?.[0]?.failed_login }
          helperText={this.state.loginErrors.email ||  this.state.wrongCredentialError?.errors?.[0]?.failed_login}
        />
      </Box>
    </>
  )
}

  renderMessage() {
    const { currentAction } = this.state;

    switch (currentAction) {
      case "Sign In":
        return (
          <>
            <Box sx={{marginBottom:"22px"}}>
            <Typography style={elemStyle.signInstyle}>{this.props.i18n?.t("register.welcomeBack")}</Typography>
            <Typography style={elemStyle.loginDes}>{this.props.i18n?.t("register.talentManagement")}</Typography>
            </Box>
          </>
        );
      case "Send Link":
        return (
          <>
          <Box sx={{marginBottom:"22px"}}>
            <Typography style={elemStyle.signInstyle}>{this.props.i18n?.t("register.ForgotPassword")}</Typography>
            <Typography style={elemStyle.loginDes}>{this.props.i18n?.t("register.talentManagement")}</Typography>
            </Box>
          </>
        );
      case "Resend e-mail":
        return (
          <>
            <Typography style={elemStyle.signInstyle}>{this.props.i18n?.t("register.checkEmail")}</Typography>
            <Typography
  style={{
    ...elemStyle.loginDes,
    direction: this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "ltr" , "rtl") 
  }}
>
  {this.state.forgotMessage}
</Typography>          </>
        );
      default:
        return null;
    }
  }

  underReviewScreen = () => {
    return (
      <>
        <Grid item md={6} xs={12} sm={12}>
          <Box sx={{ color: 'black' }} >
          <Box 
          style={{ marginTop: '20px' }}>
              <Grid container 
              justifyContent="center" alignItems="center" >
                <Grid item md={9} 
                 sm={11} xs={11}>
                  <Box style={{ padding: '1rem' }}>
                    <img onClick={this.handleNavigateLanding} 
                    style={{
                      width: "110px",height: "110px",transform: 'translateX(-10px)',
                    }} 
                    src={signInLogo.default} alt="Weddng__W" />
                  </Box> <Box sx={{ marginBottom: "22px" }}>
                    <Typography style={{fontFamily: 'Urbanist', fontWeight: 700, fontSize: '30px', lineHeight: '40px',
                     letterSpacing: '-0.5%',color: '#000000'
                    }}>
                      {this.props.i18n?.t("register.welcomeToHr")} ⏳</Typography>
                    <Typography style={{ fontFamily: 'Urbanist', fontWeight: 400, fontSize: '18px', lineHeight: '26px',
                     margin: '12px 0', color: '#475569', width: "104%" }}>
                       {this.props.i18n?.t("register.thankYou")}
                      </Typography>
                  </Box>
                  <Box><img style={{ width: "346px", height: "328px" }} src={underReviewImage.default} />
                  </Box>
                  <Box>
                    <Grid container> <Grid item md={7} sm={12}>
                        <Box sx={{
                          padding: "0.5rem", marginTop: '1.3rem',backgroundColor: "#044352",
                        }}>
                          <Button fullWidth style={{ color: "#044352" }} onClick={this.goTosignin} data-test-id="trytologin">
                            <Typography style={{ textTransform: "capitalize", 
                            color: "white" }}>{this.props.i18n?.t("register.tryToLogin")}</Typography>
                          </Button>
                        </Box>
                      </Grid></Grid>
                  </Box>
                </Grid></Grid>
            </Box>
          </Box>
        </Grid>
      </>
    )
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <CustomStyle>
        <ThemeProvider theme={theme}>
        <Grid container sx={{width : "99%", direction:this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "ltr" , "rtl") }} >
        {this.state.underReview && this.underReviewScreen()}
          {this.state.loginScreen && 
            <Grid item md={6} xs={12} sm={12}>
              <Box sx={{ color: 'black' }} >
                <Box style={{ marginTop: '20px' }}>

                  <Grid container justifyContent="center"
                    alignItems="center" >
                    <Grid item md={9} sm={11} xs={11}>
                      <Box style={{ padding: '1rem' }}>
                        <img onClick={this.handleNavigateLanding} style={{
                          
      width: "110px",
      height: "110px",
      transform: 'translateX(-10px)',
    }} src={signInLogo.default} alt="Weddng__W" />
                      </Box>
                      {this.renderMessage()}
                      {this.state.currentAction === "Sign In" && (
                        <>
                         { this.resetScreen()}
                          <Box component="form" sx={{
                            '& > :not(style)': { width: '25ch', marginTop: "0.25rem" },
                          }}
                            noValidate
                            className="PasswordInput"
                            autoComplete="off"
                          >

                            <InputLabel sx={elemStyle.loginLabel} htmlFor="my-input">
                            {this.props.i18n?.t("register.password")}
                            </InputLabel>
                            <TextField
                              data-test-id="password"
                              required
                              type={this.handleCondition(this.state.showPassword , 'text' , 'password')}
                              name="password"
                              value={this.state.loginDetails.password}
                              style={{ width: "100%", direction: "ltr" }}
                              onChange={this.handleChangeLogin}
                              error={!!this.state.loginErrors.password || !!this.state.wrongCredentialError?.errors?.[0]?.wrong_password}
                              placeholder={this.props.i18n?.t("register.yourPassword")}
                              onKeyDown={(event)=> {
                                if(event.key === "Enter"){
                                  event.preventDefault();
                                  this.handleButtonClick(event)
                                }
                              }}
                              sx={
                                elemStyle.textFeildProps
                              }
                              FormHelperTextProps={{
                                sx: {
                                  fontFamily: 'Urbanist',
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '18px',
                                  color: '#DC2626',
                                  marginLeft: 0,
                                  marginTop: '8px',
                                  textAlign: this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "left" , "right") ,
                    direction: this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "ltr" , "rtl") 
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      edge="end"
                                    >
                                      {this.handleCondition(this.state.showPassword , <Visibility /> , <VisibilityOff />)}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              helperText={this.state.loginErrors.password || this.state.wrongCredentialError?.errors?.[0]?.wrong_password}
                            />
                          </Box>
                        </>

                      )}
                      {this.state.currentAction === "Send Link" && (
                          this.resetScreen()
                      )}
                      {this.state.currentAction === "Sign In" && (


                        <Box sx={elemStyle.forgtPass}>
                          <Box>
                            <Typography
                              sx={elemStyle.forgotPassWordTitle}
                              onClick={this.handleForgotPasswordClick}
                            >{this.props.i18n?.t("register.forgotPassword")}</Typography>
                          </Box>
                        </Box>
                      )}
                      <Box>
                        <Grid container>
                          <Grid item md={9} lg={9} sm={12} xs={12}>
                            {this.state.currentAction === "Resend e-mail" ? <Box sx={{ textAlign: "center", marginTop: "0.5rem" }}>
                              <img style={{ width: "110px", height: "110px" }} src={resetPasswordImage.default} alt="Weddng__W" />
                            </Box> : ""}


                            <Box sx={{
                              padding: "0.5rem", marginTop: '3rem', background: '#044352'
                            }}>
                              <Button fullWidth
                                onClick={this.handleButtonClick}
                                data-test-id="signUpBtn">
                                  {this.state.currentAction === "Sign In" && 

                                <Typography sx={elemStyle.signInBtnTitle}>
                                   {this.props.i18n?.t("register.signIn")}
                                   </Typography>
                                  }
                                  {this.state.currentAction === "Send Link" && 
                                      <Typography sx={elemStyle.signInBtnTitle}>
                                      {this.props.i18n?.t("register.sendLink")}
                                        </Typography>
                                        }
                                  {this.state.currentAction === "Resend e-mail" && 
                                <Typography sx={elemStyle.signInBtnTitle}>
                                    {this.props.i18n?.t("register.resendEmail")}
                                  </Typography>
                                  }
                                  </Button>
                                </Box>
                                {this.state.errorMsg && this.state.errorMsg.trim() !== "" && (
                                <Typography style={{ color: "red", marginTop: "10px" , 
                                direction:this.handleCondition(this.state.selectedLanguageSignIn.toLocaleLowerCase() === "english" , "ltr" , "rtl")  }}>
                                  {this.state.errorMsg}
                                </Typography>
                              )}
                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '0.5rem',
                              marginTop: '1.5rem',
                            }}>
                              <Typography sx={elemStyle.notAccount}>
                                {this.handleCondition(this.state.currentAction === "Resend e-mail" ,
                                `${this.props.i18n?.t("register.makeMistake")}` , `${this.props.i18n?.t("register.dontAccount")}`)}

                              </Typography>
                              <Typography
                                sx={elemStyle.logStyles}
                                onClick={() => this.goToSignup()}
                              >
                                {this.props.i18n?.t("register.signUp")}
                              </Typography>
                            </Box>

                          </Grid>
                        </Grid>
                      </Box>

                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          }
            <Grid item md={6} xs={12} sm={12}>
              <Box
                sx={{
                  position: 'relative',
                color: 'white',
                textAlign: 'center',
                fontFamily: 'poppins',
                height: '100vh',
                width: '100%',
                overflow: 'hidden',
                }}
              >
                <img src={signInImage.default} alt="Weddng__W" style={{
                  width: '100%', filter: 'brightness(100%)', borderRadius: "0px 0px 0px 64px",
                  height: '100%',
                  objectFit: 'cover',
                }} />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '79%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '10px',
                    width: '100%',

                  }}
                >
                  <p style={elemStyle.signInTitle}>
                  {this.props.i18n?.t("register.revolutionizeAI")}
                  </p>
                  <Box style={{ width: "100%" }}>
                    <p style={elemStyle.signInSDescr}> {this.props.i18n?.t("register.talentManagement")} </p>
                  </Box>
                </Box>

              </Box>
            </Grid>
          </Grid>



        </ThemeProvider>
      </CustomStyle>
      // Customizable Area End
    );
  }

}

  // Customizable Area Start
  export const EmailAccountLoginBlockWeb = withTranslation()(EmailAccountLoginBlock)
  const CustomStyle = styled(Box)({
    "& .MuiInputBase-input":{
      direction: "ltr !important",
    },
  })
  // Customizable Area End
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
